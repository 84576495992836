import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Image, ListGroup, Button } from 'react-bootstrap';
import { ArrowLeft, Star, StarFill, Box, ChatDots, InfoCircle, Gear, FileText, Shield, BoxArrowRight } from 'react-bootstrap-icons';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { MerchandShopDetail } from '../../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogin } from '../../redux/slice/LoginSlice';

export default function AccountPage() {
  const BaseURL = process.env.REACT_APP_Base_URL;
  const ImageURL = process.env.REACT_APP_Image_URL;
  const shopID = useSelector(state => state.shop.shopID)

  const [shopData, setShopData] = useState({});
  const [shopRating, setShopRating] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLogin = useSelector(state => state.login.isLogin);
  const ShopData = useMutation(MerchandShopDetail, {
    onSuccess: (res) => {
      console.log({ res });

      setShopData(res.Data.shopDetails)
      setShopRating(res.Data.shopDetails.ShopRating)
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const fetchShopData = async () => {
    try {

      const formData = new FormData();
      formData.append('ShopID', shopID); // Use the Redux store value
      await ShopData.mutateAsync(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const logout = () => {
    localStorage.removeItem('7NMUser')
    localStorage.removeItem('7NMUserRegiter')
    localStorage.removeItem('7NMUPhone')
    dispatch(setIsLogin(false))

  }

  useEffect(() => {
    fetchShopData();
  }, [])

  useEffect(() => {
    if (!isLogin) {
      navigate('/')
    }


  }, [isLogin])
  const handleEditClick = () => {
    navigate('/account/edit-shop');
  };

  return (
    <>
      {
        ShopData.isLoading ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
        }}>
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> :
          <Container className="py-4">

            <Row className="mb-4 align-items-center">
              <Col>
                <h1 className="mb-0">
                  Account
                </h1>
              </Col>
            </Row>

            <Card className="mb-4">
              <Card.Body>
                <Row className="align-items-center">
                  <Col xs={4} md={3} lg={2}>
                    <Image src={ImageURL + shopData.ShopImage} style={{
                      height: '100px',
                      width: '100px',
                      objectFit: 'cover'
                    }} roundedCircle fluid />
                  </Col>
                  <Col xs={8} md={9} lg={10}>
                    <Card.Title className="mb-1">{shopData.ShopName}</Card.Title>
                    <Card.Text className="text-muted mb-2">{shopData.ShopAddress}</Card.Text>
                    <div className="d-flex align-items-center">

                      {[...Array(Math.floor(shopRating))].map((_, index) => (
                        <StarFill key={index} className="text-warning me-1" />
                      ))}
                      {[...Array(5 - Math.floor(shopRating))].map((_, index) => (
                        <Star key={index} className="text-warning me-1" />
                      ))}
                    </div>
                    <div>
                      <Button variant="danger" size='sm' className='mt-4' onClick={handleEditClick}>
                        Edit Shop
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card>
              <ListGroup variant="flush">

                <Link to={"/account/menu-list"} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListGroup.Item action className="d-flex align-items-center">
                    <Box className="me-3" />
                    Menu list
                  </ListGroup.Item>
                </Link>

                <ListGroup.Item action className="d-flex align-items-center">
                  <ChatDots className="me-3" />
                  Contact Us
                </ListGroup.Item>
                <a target="_blank" style={{ textDecoration: 'none' }} href='https://7nian.in/sevennianadmin/aboutUs'>
                  <ListGroup.Item action className="d-flex align-items-center">
                    <InfoCircle className="me-3" />
                    About Us
                  </ListGroup.Item>
                </a>


                <Link to={"/account/settings"} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <ListGroup.Item action className="d-flex align-items-center">
                    <Gear className="me-3" />
                    Settings
                  </ListGroup.Item>
                </Link>
                <a target="_blank" style={{ textDecoration: 'none' }} href='https://7nian.in/sevennianadmin/tandc'>
                  <ListGroup.Item action className="d-flex align-items-center">
                    <FileText className="me-3" />
                    T&C
                  </ListGroup.Item>
                </a>
                <a target="_blank" style={{ textDecoration: 'none' }} href='https://7nian.in/sevennianadmin/privacyPolicy' >
                  <ListGroup.Item action className="d-flex align-items-center">
                    <Shield className="me-3" />
                    Privacy Policy
                  </ListGroup.Item>
                </a>
              </ListGroup>

            </Card>

            <div className="d-grid gap-2 mt-4">
              <Button variant="outline-warning" onClick={() => {
                if (window.confirm("Are you sure you want to logout?")) {
                  logout(); // Call logout function if confirmed
                }
              }} size="lg" className="d-flex align-items-center justify-content-center">
                <BoxArrowRight className="me-2" />
                Logout
              </Button>

            </div>
          </Container >
      }
    </>
  );
}