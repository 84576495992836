import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { setIsLogin, setRegStep } from '../redux/slice/LoginSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setShopID, setShopPhone } from '../redux/slice/ShopSlice';

const Login = () => {
    const styles = {
        container: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5',
        },
        box: {
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '15px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            overflow: 'hidden',
        },
        countryCode: {
            backgroundColor: '#f9f9f9',
            padding: '10px',
            borderRight: '1px solid #ddd',
        },
        input: {
            border: 'none',
            padding: '10px',
            flex: 1,
        },
        button: {
            width: '100%',
            padding: '15px',
            backgroundColor: '#ff7f00',
            color: '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1em',
        },
        otpInput: {
            width: '40px',
            height: '40px',
            marginRight: '10px',
            textAlign: 'center',
            fontSize: '1.5em',
        },

        footerText: {
            marginTop: '15px',
            fontSize: '0.85em',
            color: '#777',
            textAlign: 'center',
        },
        link: {
            color: '#ff7f00',
            textDecoration: 'none',
        },
    };
    const regStep = useSelector(state => state.login.regStep)

    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const BaseURL = process.env.REACT_APP_Base_URL;
    const [phoneNumber, setPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [timer, setTimer] = useState(60);
    const [resendEnabled, setResendEnabled] = useState(false);
    const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const shopID = useSelector(state => state.shop.shopID)
    const shopPhone = useSelector(state => state.shop.shopPhone)

    const navigate = useNavigate(); // Initialize useNavigate

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value === '') {
            setPhoneNumber('');
            return;
        }
        if (/^[6-9][0-9]{0,9}$/.test(value)) {
            setPhoneNumber(value);
        }
    };

    const handleLogin = (e) => {
        e?.preventDefault(); // Prevent form submission

        if (phoneNumber.length !== 10) {
            setErrorMessage('Please enter a valid 10-digit phone number.');
            return;
        }
        setIsLoading(true)
        const formData = new FormData();
        formData.append('phone', phoneNumber);
        axios.post(BaseURL + '/MerchantSendOTP', formData)
            .then((response) => {
                console.log('API Response:', response.data);
                setErrorMessage('');
                setShowOtpInput(true); // Show OTP input after successful response
                setTimer(60); // Reset timer
                setResendEnabled(false); // Disable Resend button
                setIsLoading(false)
            })
            .catch((error) => {
                console.error('API Error:', error);
                setErrorMessage('Something went wrong. Please try again later.');
                setIsLoading(false)
            });
    };

    const handleOtpChange = (value, index) => {
        if (/^\d$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value !== '' && index < otpRefs.length - 1) {
                otpRefs[index + 1].current.focus(); // Auto-move to next input
            }
        }
    };
    const handleLoginSuccess = async (user) => {
        // await localStorage.setItem('7NMUser', JSON.stringify(user.ShopID))
        await localStorage.setItem('7NMUPhone', JSON.stringify(user.ShopPhone))

        dispatch(setIsLogin(true))
        dispatch(setShopID(user.ShopID))
        dispatch(setShopPhone(user.ShopPhone))
        navigate('/home'); // Navigate to the home page
    }
    const handleVerify = async () => {
        const enteredOtp = otp.join('');
        if (enteredOtp.length == 4) {
            setIsLoading(true)
            // Call verify API
            await axios.post(BaseURL + '/MerchantVerifyOTP', { otp: enteredOtp, phone: phoneNumber })
                .then((response) => {
                    setIsLoading(false)
                    setErrorMessage('');
                    console.log(response.data.response_code)
                    if (response.data.response_code == 0 && response.data.status == true) {
                      
                        localStorage.setItem('7NMUser', JSON.stringify(response.data.user_data.ShopID))
                        if (response.data.shopstatus == 0) {
                            handleLoginSuccess(response.data.user_data);
                        } else {
                            dispatch(setIsLogin(true))
                            navigate('/approval-pending');
                        }

                    } else if (response.data.response_code > 0) {
              

                        const user = response.data.user_data;
                        localStorage.setItem('7NMUserRegiter', JSON.stringify(user.ShopID));
                        localStorage.setItem('7NMUPhone', JSON.stringify(user.ShopPhone))
                        // dispatch(setIsLogin(true));
                        dispatch(setShopID(user.ShopID));
                        dispatch(setShopPhone(user.ShopPhone));
                        dispatch(setRegStep(response.data.response_code)); // Set regStep here
                        navigate('/register'); // Redirect after setting regStep
                        setIsLoading(false)
                    }
                })
                .catch((error) => {
                    console.log('INN3');

                    console.error('OTP Verification Error:', error);
                    setErrorMessage('OTP Verification failed. Try again.');
                    setIsLoading(false)
                });
        } else {
            console.log('INN4');

            setIsLoading(false)
            setErrorMessage('Please enter a valid 4-digit OTP.');

        }
    };

    useEffect(() => {
        if (regStep > 0) {
            navigate('/register');
        }
    }, [regStep]);

    const handleResendOtp = () => {
        console.log({ resendEnabled })
        if (resendEnabled) {
            // Resend OTP API call
            setOtp(['', '', '', ''])
            handleLogin()
            setTimer(60); // Reset timer after resending
            setResendEnabled(false);
        }
    };
    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setResendEnabled(true); // Enable resend when timer hits 0
        }
    }, [timer]);

    return (
        <Container fluid style={styles.container}>
            <Row className="justify-content-center align-items-center w-100">
                <Col xs={12} md={6} lg={4} style={styles.box}>
                    <div style={styles.header}>
                        <h2>{showOtpInput ? 'OTP Verification' : 'Log in or sign up'}</h2>
                    </div>
                    {!showOtpInput ? (
                        <Form onSubmit={handleLogin}>
                            <Form.Group style={{ marginBottom: '15px' }}>
                                <div style={styles.inputGroup}>
                                    <span style={styles.countryCode}>+91</span>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Phone Number"
                                        value={phoneNumber}
                                        onChange={handleInputChange}
                                        style={styles.input}
                                        maxLength="10"
                                    />
                                </div>
                            </Form.Group>
                            {isLoading ? <Button
                                style={{
                                    ...styles.button, // Spread the styles from the 'styles.button' object
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="spinner-border text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </Button> : <Button style={styles.button} type="submit">
                                Login
                            </Button>}

                        </Form>
                    ) : (
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '15px' }}>
                                {otp.map((digit, idx) => (
                                    <input
                                        key={idx}
                                        ref={otpRefs[idx]}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(e.target.value, idx)}
                                        style={styles.otpInput}
                                    />
                                ))}
                            </div>
                            {isLoading ? <Button
                                style={{
                                    ...styles.button, // Spread the styles from the 'styles.button' object
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="spinner-border text-white" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </Button> :
                                <Button style={styles.button} onClick={handleVerify}>
                                    Verify
                                </Button>
                            }
                            <p className='text-center mt-2'>
                                {!resendEnabled ? <>
                                    <a >
                                        Resend OTP
                                    </a>
                                    <span> in: 00:{timer < 10 ? `0${timer}` : timer}</span>
                                </> :
                                    <>
                                        Didn't receive the otp?
                                        <a className='ms-1' style={styles.link} onClick={() => resendEnabled ? handleResendOtp() : null}>
                                            Resend OTP
                                        </a>
                                    </>


                                }

                            </p>
                            {/* <Button
                                style={styles.button}
                                onClick={handleResendOtp}
                                disabled={!resendEnabled}
                            >
                                Resend OTP
                            </Button> */}
                        </div>
                    )}
                    {errorMessage && (
                        <div style={styles.errorMessage}>
                            {errorMessage}
                        </div>
                    )}
                    <div style={styles.footerText}>
                        <p>
                            By continuing, you agree to our{' '}
                            <a href='https://7nian.in/sevennianadmin/tandc' style={styles.link}>
                                Terms of service
                            </a>
                            ,{' '}
                            <a href='https://7nian.in/sevennianadmin/privacyPolicy' style={styles.link}>
                                Privacy Policy
                            </a>
                            , and{' '}
                            <a href='https://7nian.in/sevennianadmin/aboutUs' style={styles.link}>
                                Content Policy
                            </a>
                            .
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;


