import React, { useState, useEffect } from 'react'
import { Form, Button, Container, Row, Col, Image, Alert, Modal, Spinner } from 'react-bootstrap'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'
import { getShopData, getShopTypes, merchantGetCategories, merchantGetSubCategories, UpdateShopData } from '../../utils/api'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import { Star, Upload, MapPin, ImageIcon } from 'lucide-react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'

const locationIcon = L.icon({
  iconUrl: '/locationpin.jpg',
  iconSize: [35, 45],
  iconAnchor: [17, 46],
  popupAnchor: [0, -46],
})

function LocationMarker({ onSelectLocation }) {
  const [position, setPosition] = useState(null)

  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng
      onSelectLocation({ lat, lng })
      setPosition(e.latlng)
    },
  })

  return position === null ? null : (
    <Marker position={position} icon={locationIcon}></Marker>
  )
}

const MapModal = ({ show, handleClose, latLng, onSelectLocation }) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MapContainer
          center={[latLng.lat, latLng.lng]}
          zoom={13}
          style={{ height: '400px', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; '
          />
          <LocationMarker onSelectLocation={onSelectLocation} />
          {latLng && (
            <Marker position={[latLng.lat, latLng.lng]} icon={locationIcon}></Marker>
          )}
        </MapContainer>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditShopDetails = () => {
  const [ownerName, setOwnerName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [rating, setRating] = useState(0)
  const [isActive, setShopIsActive] = useState(true)
  const [allowPrebooking, setAllowPrebooking] = useState(false)
  const [shopName, setShopName] = useState('')
  const [storeType, setStoreType] = useState('')
  const [address, setAddress] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [bankName, setBankName] = useState('')
  const [ifscCode, setIfscCode] = useState('')
  const [gstNumber, setGstNumber] = useState('')
  const [shopRating, setShopRating] = useState('')
  const [image, setImage] = useState(null)
  const [gstCertificate, setGstCertificate] = useState(null)
  const [foodSafetyCertificate, setFoodSafetyCertificate] = useState(null)
  const [error, setError] = useState({})
  const [success, setSuccess] = useState('')
  const [showMap, setShowMap] = useState(false)
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedSubCategories, setSelectedSubCategories] = useState({})

  const [productImagePreview, setProductImagePreview] = useState(null);

  const [selectedSubCatIDs, setSelectedSubCatIDs] = useState([]);
  const [initialImage, setInitialImage] = useState(null);

  const [gstCertificatePreview, setGstCertificatePreview] = useState(null);
  const [initialGstCertificate, setInitialGstCertificate] = useState(null);

  const [foodSafetyCertificatePreview, setFoodSafetyCertificatePreview] = useState(null);
  const [initialFoodSafetyCertificate, setInitialFoodSafetyCertificate] = useState(null);


  const shopID = typeof window !== 'undefined' ? localStorage.getItem('7NMUser') || '' : ''

  console.log("selected sub ", selectedSubCategories)
  const [latLng, setLatLng] = useState({
    lat: '8.179244',
    lng: '77.412116',
  })

  const navigate = useNavigate();


  const onSelectLocation = (newLatLng) => {
    setLatLng(newLatLng)
    // setAddress(`Lat: ${newLatLng.lat}, Lng: ${newLatLng.lng}`)
  }

  const handleOpenMap = () => {
    setShowMap(true)
  }

  const handleCloseMap = () => {
    setShowMap(false)
  }



  const categoryOptions = categories?.map(category => ({
    value: category.CatID,
    label: category.CatName,
  }))


  const UpdateShop = useMutation(UpdateShopData, {
    onSuccess: (res) => {
      console.log(res);
      Swal.fire({
        icon: 'success',
        title: 'Shop update requested',
        text: 'Update request sent successfully!',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    },
    onError: (error) => {
      console.error("Error fetching categories:", error);
    },
  });


  const getcategories = useMutation(() => merchantGetCategories(shopID), {
    onSuccess: (res) => {
      // console.log({res})
      setCategories(res.Data);
    },
    onError: (error) => {
      console.error("Error fetching categories:", error);
    },
  });

  const getSubCategories = useMutation((catID) => merchantGetSubCategories(catID), {
    onSuccess: (res) => {
      setSubCategories(res);
    },
    onError: (error) => {
      console.error('Error fetching subcategories:', error);
    },
  });



  const { data: shopTypes, isLoading, error: typesError } = useQuery('shopTypes', getShopTypes);

  const fetchCategories = async () => {
    try {
      const formData = new FormData()
      formData.append('shopid', shopID)
      await getcategories.mutateAsync(formData)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchSubCategories = async (catId) => {
    try {
      console.log("cat", catId);
      // Pass catId directly to mutateAsync
      await getSubCategories.mutateAsync(catId);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    setSelectedCategories(selectedOptions)
    fetchSubCategories(selectedOptions.map(option => option.value))
  }


  const handleSubCategoryChange = (categoryID, subCatID, isChecked) => {
    setSelectedSubCategories((prev) => {
      const currentSubCats = prev[categoryID] || [];
      if (isChecked) {
        return {
          ...prev,
          [categoryID]: [...currentSubCats, subCatID], 
        };
      } else {
        return {
          ...prev,
          [categoryID]: currentSubCats.filter(id => id !== subCatID), // Remove subcategory ID
        };
      }
    });
  };

  const handleProductImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      setImage(file); // Store the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImagePreview(reader.result); // Set image preview URL
      };
      reader.readAsDataURL(file); // Read file as data URL to display preview
    }
  };

  const handleGstCertificateChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      setGstCertificate(file); // Store the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setGstCertificatePreview(reader.result); // Set image preview URL
      };
      reader.readAsDataURL(file); // Read file as data URL to display preview
    }
  };

  const handleFoodSafetyCertificateChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      setFoodSafetyCertificate(file); // Store the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setFoodSafetyCertificatePreview(reader.result); // Set image preview URL
      };
      reader.readAsDataURL(file); // Read file as data URL to display preview
    }
  };


  const ImageURL = process.env.REACT_APP_Image_URL;


  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const shopData = await getShopData(shopID);

        populateForm(shopData?.Data);
        // console.log({ shopData })
      } catch (err) {
        console.error('Failed to fetch shop data', err.message);
        setError(err.message);
      }
    };

    fetchShopData();
    fetchCategories()
  }, []);

  // Function to populate form with fetched data
  const populateForm = (data) => {
    console.log("d", data)
    setOwnerName(data.ShopOwnerName || '');
    setEmail(data.ShopEmail || '');
    setPhoneNumber(data.ShopPhone || '');
    setShopName(data.ShopName || '');
    setStoreType(data.ShopTypeID || '');
    setAddress(data.ShopAddress || '');
    setAccountNumber(data.ShopAccountNo || '');
    setBankName(data.ShopBankName || '');
    setIfscCode(data.ShopIFSC || '');
    setGstNumber(data.ShopGSTNo || '');
    setRating(data.ShopRating || 0);
    setShopIsActive(data.ShopStatus == 1 ? true : false || false);
    setAllowPrebooking(data.ShopAcceptPreBooking == 1 ? true : false || false);

    setProductImagePreview(data.ShopImage ? `${ImageURL}/${data?.ShopImage}` : null);
    setImage(data.ShopImage);
    setInitialImage(data.ShopImage);

    setGstCertificate(data.ShopGstDocument);
    setInitialGstCertificate(data.ShopGstDocument);
    setGstCertificatePreview(data.ShopGstDocument ? `${ImageURL}/${data?.ShopGstDocument}` : null)

    setFoodSafetyCertificate(data.ShopDocument);
    setInitialFoodSafetyCertificate(data.ShopDocument);
    setFoodSafetyCertificatePreview(data.ShopDocument ? `${ImageURL}/${data?.ShopDocument}` : null)

    setLatLng({ ...latLng, lat: data.ShopLatitude, lng: data.ShopLongitude } || {})

    // const selectedShopCategoryIds = data.ShopCategory.split(',').map(Number);


    const selectedCategories = data.Categories.map(category => ({ value: category.CatID, label: category.CatName }));
    setSelectedCategories(selectedCategories.length > 0 ? selectedCategories : []);

    // Populate selected subcategories
    const selectedSubCatIDs = data.SubCategories.map(subCat => subCat.CatID);
    setSelectedSubCatIDs(selectedSubCatIDs);


    const newSubCategories = {};

    selectedCategories.forEach(category => {
      newSubCategories[category.value] = selectedSubCatIDs.filter(id => id === category.value);
    });


    console.log("api from ", selectedCategories)

    setSelectedSubCategories(newSubCategories);

    // Fetch subcategories based on the selected categories
    fetchSubCategories(selectedCategories.map(option => option.value));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({});

    const newErrors = {};
    if (!ownerName) newErrors.ownerName = "Owner name is required.";
    if (!email) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = "Email format is invalid.";
    if (!phoneNumber) newErrors.phoneNumber = "Phone number is required.";
    if (!shopName) newErrors.shopName = "Shop name is required.";
    if (!storeType) newErrors.storeType = "Store type is required.";
    if (!address) newErrors.address = "Address is required.";
    if (!accountNumber) newErrors.accountNumber = "Account number is required.";
    if (!bankName) newErrors.bankName = "Bank name is required.";
    if (!ifscCode) newErrors.ifscCode = "IFSC code is required.";
    if (!gstNumber) newErrors.gstNumber = "GST number is required.";
    if (rating < 1) newErrors.rating = "Rating must be between 1 and 5.";

    if (isActive && !productImagePreview) {
      newErrors.image = "Image upload is required when the status is active.";
    }
    if (!gstCertificate) {
      newErrors.gstCertificate = "GST Certificate upload is required.";
    }
    if (!foodSafetyCertificate) {
      newErrors.foodSafetyCertificate = "Food Safety Certificate upload is required.";
    }

    // Validation for categories and subcategories
    if (selectedCategories.length === 0) {
      newErrors.categories = "At least one category must be selected.";
    } else {
      selectedCategories.forEach((category) => {
        // Combine current selected and pre-fetched selected subcategories
        const currentSubCategories = selectedSubCategories[category.value] || [];
        const preFetchedSubCategories = selectedSubCatIDs.filter(subCatID => subCategories
          .find(subCategory => subCategory.CategoryID === category.value)
          ?.Data.some(data => data.CatID === subCatID));

        const allSelectedSubCategories = [...currentSubCategories, ...preFetchedSubCategories];

        // Check if no subcategories are selected
        if (allSelectedSubCategories.length === 0) {
          newErrors.subCategories = newErrors.subCategories || {};
          newErrors.subCategories[category.value] = `At least one subcategory must be selected in ${category.label}.`;
        }
      });
    }

    const lat = parseFloat(latLng.lat);
    const lng = parseFloat(latLng.lng);

    if (isNaN(lat) || lat < -90 || lat > 90) {
      newErrors.latLng = "Latitude must be a number between -90 and 90.";
    }

    if (isNaN(lng) || lng < -180 || lng > 180) {
      newErrors.latLng = newErrors.latLng || {};
      newErrors.latLng = "Longitude must be a number between -180 and 180.";
    }

    // If there are errors, set them and return
    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
      return;
    }

    const formData = new FormData();

    if (image !== initialImage) {
      formData.append('shopimage', image);
    }
    if (gstCertificate !== initialGstCertificate) {
      formData.append('shopgstdocument', gstCertificate);
    }
    if (foodSafetyCertificate !== initialFoodSafetyCertificate) {
      formData.append('shopdocument', foodSafetyCertificate);
    }
    formData.append('shopaccount', accountNumber);
    formData.append('address', address);
    formData.append('ShopIFSC', ifscCode); 
    formData.append('preBookingAvailable', allowPrebooking ? 1 : 0); 
    formData.append('shopgst', gstNumber); 
    formData.append('latitude', latLng.lat)
    formData.append('longitude', latLng.lng)
    formData.append('shoptype', storeType); 
    formData.append('ShopBankName', bankName); 
    formData.append('shoprating', rating); 
    // formData.append('shoptiming', shopTiming); 
    formData.append('phone', phoneNumber); 
    formData.append('shopname', shopName);
    formData.append('name', ownerName); 
    formData.append('shopId', shopID);
    formData.append('email', email); 
    formData.append('status', isActive ? 1 : 0); 

    const categoryIds = selectedCategories.map(category => category.value).join(',');
    formData.append('catid', categoryIds)

    const subCategoryIds = Object.entries(selectedSubCategories)
      .flatMap(([categoryId, selectedIds]) =>
        selectedIds.map(id => `${categoryId},${id}`)
      )
      .join(',');
    formData.append('subcatid', subCategoryIds);

    // Debugging: log formData contents
    for (let [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }

    await UpdateShop.mutateAsync(formData)

  };

  useEffect(() => {
    const fetchShopTypes = async () => {
      const data = await getShopData(shopID);
      setStoreType(data.Data.ShopTypeID);

    };

    fetchShopTypes();
  }, []);


  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Edit Shop Details</h2>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <h5 className="mb-3">Owner Details</h5>
            <Form.Group controlId="ownerName" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
                isInvalid={!!error.ownerName}
              />
              <Form.Control.Feedback type="invalid">{error.ownerName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!error.email}
              />
              <Form.Control.Feedback type="invalid">{error.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phoneNumber" className="mb-3">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter phone number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                isInvalid={!!error.phoneNumber}
              />
              <Form.Control.Feedback type="invalid">{error.phoneNumber}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <h5 className="mb-3">Shop Details</h5>
            <Form.Group controlId="shopName" className="mb-3">
              <Form.Label>Shop Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter shop name"
                value={shopName}
                onChange={(e) => setShopName(e.target.value)}
                isInvalid={!!error.shopName}
              />
              <Form.Control.Feedback type="invalid">{error.shopName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="storeType" className="mb-3">
              <Form.Label>Store Type</Form.Label>
              <Form.Control
                as="select"
                value={storeType}
                onChange={(e) => setStoreType(e.target.value)}
                isInvalid={!!error.storeType}
                disabled={isLoading}
              >
                <option value="">Select store type</option>
                {isLoading ? (
                  <option value="" disabled>
                    <Spinner animation="border" size="sm" /> Loading...
                  </option>
                ) : (
                  shopTypes?.map((shopType) => (
                    <option key={shopType.ShopTypeID} value={shopType.ShopTypeID} selected={shopType.ShopTypeID == storeType}
                    >
                      {shopType.ShopTypeName}
                    </option>
                  ))
                )}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{error.storeType}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="address" className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                isInvalid={!!error.address}
              />
              <Form.Control.Feedback type="invalid">{error.address}</Form.Control.Feedback>
            </Form.Group>

            <Button variant="outline-primary" onClick={handleOpenMap} className="mb-3">
              <MapPin className="mr-2" /> Choose Location
            </Button>
            <p>Lat: {latLng.lat}, Lng: {latLng.lng}</p>
            {error.latLng && (
              <div className="text-danger mb-3">{error.latLng}</div>
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={6}>
            <h5 className="mb-3">Banking Details</h5>
            <Form.Group controlId="accountNumber" className="mb-3">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter account number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                isInvalid={!!error.accountNumber}
              />
              <Form.Control.Feedback type="invalid">{error.accountNumber}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="bankName" className="mb-3">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter bank name"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                isInvalid={!!error.bankName}
              />
              <Form.Control.Feedback type="invalid">{error.bankName}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="ifscCode" className="mb-3">
              <Form.Label>IFSC CODE</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter IFSC code"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                isInvalid={!!error.ifscCode}
              />
              <Form.Control.Feedback type="invalid">{error.ifscCode}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={6}>
            <h5 className="mb-3">Other Details</h5>
            <Form.Group controlId="gstNumber" className="mb-3">
              <Form.Label>GST Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter GST number"
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                isInvalid={!!error.gstNumber}
              />
              <Form.Control.Feedback type="invalid">{error.gstNumber}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="rating" className="mb-3">
              <Form.Label>Rating</Form.Label>
              <div>
                {[...Array(5)].map((_, index) => (
                  <Star
                    key={index}
                    className={`cursor-pointer ${index < rating ? 'text-warning' : 'text-muted'}`}
                    onClick={() => setRating(index + 1)}
                  />
                ))}
              </div>
              {error.rating && <div className="text-danger">{error.rating}</div>}
            </Form.Group>

            <Form.Group controlId="status" className="mb-3">
              <Form.Check
                type="switch"
                id="status-switch"
                label="Status"
                checked={isActive}
                onChange={(e) => setShopIsActive(e.target.checked)}
              />
            </Form.Group>

            <Form.Group controlId="prebooking" className="mb-3">
              <Form.Check
                type="switch"
                id="prebooking-switch"
                label="Prebooking"
                checked={allowPrebooking}
                onChange={(e) => setAllowPrebooking(e.target.checked)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md={6}>
            <h5 className="mb-3">Service Details</h5>
            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Select
                isMulti
                options={categoryOptions}
                value={selectedCategories}
                onChange={handleCategoryChange}
              />
              {error.categories && <div className="text-danger">{error.categories}</div>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Sub Category</Form.Label>
              {subCategories.map((category) => (
                <div key={category.CategoryID}>
                  <h6>{category.CategoryName}</h6>
                  {category.Data.map((subCategory) => (
                    <Form.Check
                      key={subCategory.CatID}
                      type="checkbox"
                      id={`sub-category-${subCategory.CatID}`}
                      label={subCategory.CatName}
                      checked={selectedSubCategories[category.CategoryID]?.includes(subCategory.CatID) || selectedSubCatIDs.includes(subCategory.CatID)}
                      onChange={(e) => handleSubCategoryChange(category.CategoryID, subCategory.CatID, e.target.checked)}
                    />
                  ))}
                  {error.subCategories?.[category.CategoryID] && <div className="text-danger">{error.subCategories[category.CategoryID]}</div>}

                </div>
              ))}
            </Form.Group>
          </Col>

          <Col md={6}>
            <h5 className="mb-3">Uploads</h5>
            <Row>
              {/* Upload Image */}
              <Col md={6}>
                {productImagePreview ? (
                  <Image src={productImagePreview} alt="Product Preview" thumbnail fluid className="mb-2" width={150}
                    height={150} />
                ) : (
                  <ImageIcon size={48} className="text-warning mb-2" />
                )}
                <Form.Group controlId="image" className="mb-3">
                  <Form.Label>Upload Image</Form.Label>

                  <div className="d-flex align-items-center">

                    <Button variant="outline-secondary" onClick={() => document.getElementById('fileInput').click()}>
                      <Upload className="mr-2" /> Edit Image
                    </Button>
                    <input
                      id="fileInput"
                      type="file"
                      hidden
                      onChange={handleProductImageChange}
                    // onChange={(e) => setImage(e.target.files[0])}
                    />

                  </div>
                  {error.image && <div className="text-danger">{error.image}</div>}
                </Form.Group>
              </Col>

              {/* Upload GST Certificate */}
              <Col md={6}>
                <Form.Group controlId="gstCertificate" className="mb-3">
                  <Form.Label>Upload GST Certificate</Form.Label>
                  <div className="d-flex align-items-center">
                    <Button variant="outline-secondary" onClick={() => document.getElementById('gstCertificateInput').click()}>
                      <Upload className="mr-2" /> Upload GST Certificate
                    </Button>
                    <input
                      id="gstCertificateInput"
                      type="file"
                      hidden
                      onChange={handleGstCertificateChange}
                    />
                  </div>
                  {error.gstCertificate && <div className="text-danger">{error.gstCertificate}</div>}
                </Form.Group>
                {/* Show preview if gstCertificatePreview is set */}
                {gstCertificatePreview && (
                  <Image src={gstCertificatePreview} alt="GST Certificate Preview" thumbnail fluid className="mb-2" width={150} height={150} />
                )}
              </Col>

              {/* Upload Food Safety Certificate */}
              <Col md={12}>
                <Form.Group controlId="foodSafetyCertificate" className="mb-3">
                  <Form.Label>Upload Food Safety Certificate</Form.Label>
                  <div className="d-flex align-items-center">
                    <Button variant="outline-secondary" onClick={() => document.getElementById('foodSafetyCertificateInput').click()}>
                      <Upload className="mr-2" /> Upload Food Safety Certificate
                    </Button>
                    <input
                      id="foodSafetyCertificateInput"
                      type="file"
                      hidden
                      onChange={handleFoodSafetyCertificateChange}
                    />
                  </div>
                  {error.foodSafetyCertificate && <div className="text-danger">{error.foodSafetyCertificate}</div>}
                </Form.Group>
                {/* Show preview if foodSafetyCertificatePreview is set */}
                {foodSafetyCertificatePreview && (
                  <Image src={foodSafetyCertificatePreview} alt="Food Safety Certificate Preview" thumbnail fluid className="mb-2" width={150} height={150} />
                )}
              </Col>

            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="text-center">
            <Button variant="warning" type="submit" size="lg" disabled={UpdateShop.isLoading}>
              {UpdateShop.isLoading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Col>
        </Row>
      </Form>

      <MapModal
        show={showMap}
        handleClose={handleCloseMap}
        latLng={latLng}
        onSelectLocation={onSelectLocation}
      />
    </Container>
  )
}

export default EditShopDetails