import { configureStore } from '@reduxjs/toolkit'
import LoginSlice from './slice/LoginSlice'
import  ValidateSlice  from './slice/Validations'
import ShopSlice from './slice/ShopSlice'
import OrderSlice from './slice/OrderSlice'

export const store = configureStore({
  reducer: {
    login: LoginSlice,
    shop: ShopSlice,
    validation:ValidateSlice,
    orders:OrderSlice,
  },
})