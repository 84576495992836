import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge, Form, Nav, Pagination } from 'react-bootstrap'
import { Bell, Magic, Calendar, UsbDrive } from 'react-bootstrap-icons'
import { useMutation } from 'react-query';
import { MerchantHome } from '../utils/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { useSelector } from 'react-redux';

import './Registeration/Register.css'

export default function Dashboard() {
  const shopID = useSelector(state => state.shop.shopID)

  const BaseURL = process.env.REACT_APP_Base_URL;
  const [homeData, setHomeData] = useState({});
  const navigate = useNavigate();
  const [time, setTime] = useState('10:00');

  const [selectedTime, setSelectedTime] = useState(new Date());
 
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(homeData.OrderDetails?.length / ordersPerPage);

  // Get the orders for the current page
  const currentOrders = homeData.OrderDetails?.slice(
    (currentPage - 1) * ordersPerPage,
    currentPage * ordersPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleTimeChange = (date) => {
    setSelectedTime(date);
    console.log('Selected time:', date);
  };


  const homeDetails = useMutation(MerchantHome, {
    onSuccess: (res) => {
      console.log({ res });
      setHomeData(res.Data)
    },
    onError: (error) => {
      console.error(error);
    },
  });


  const fetchHomeData = async () => {

    try {
      const formData = new FormData();
      formData.append('ShopID', shopID); // Use the Redux store value
      await homeDetails.mutateAsync(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const changeShopAvilability = async (status) => {
    const formData = new FormData();
    formData.append('shopId', shopID);
    formData.append('status', status);
    await axios.post(BaseURL + '/changeShopTiming', formData)
      .then(() => {
        fetchHomeData();
      })
  }

  useEffect(() => {
    fetchHomeData();
  }, [])



  return (
    <Container fluid className="bg-light min-vh-100">
      {homeDetails.isLoading ? <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '90vh',
      }}>
        <div className="spinner-border text-warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div> :

        <Container className="py-4">
          {/* <Card className="mb-4">
            <Card.Body>
              <h5>Status: <Badge bg="danger">PENDING</Badge></h5>
            </Card.Body>
          </Card> */}

          <Row className="mb-4">
            <Col md={6} className="mb-3 mb-md-0">
              <Card className="text-center h-100">
                <Card.Body>
                  <Card.Title className="text-warning">PENDING</Card.Title>
                  <Card.Text className="display-4">{homeData.TotalPendingCount}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="text-center h-100">
                <Card.Body>
                  <Card.Title className="text-success">RUNNING</Card.Title>
                  <Card.Text className="display-4">{homeData.Running}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Active Products</Card.Title>
                  <Card.Text className="h3">{homeData.ActiveProducts}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Unavailable Products</Card.Title>
                  <Card.Text className="h3">{homeData.UnavailableProducts}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Today Sales (₹)</Card.Title>
                  <Card.Text className="h3">₹{homeData.TodaySalesAmount}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Today Sales (Qty)</Card.Title>
                  <Card.Text className="h3">{homeData.TodaySalesQty}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Amount Received (₹)</Card.Title>
                  <Card.Text className="h3">₹{homeData.AmountReceived}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Amount Pending (₹)</Card.Title>
                  <Card.Text className="h3">₹{homeData.AmountPending}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card className="mb-4 bg-warning text-white">
            <Card.Body className="text-center">
              <Card.Title>Total Orders</Card.Title>
              <Card.Text className="display-4">{homeData.TotalOrders}</Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Body className="d-flex justify-content-between align-items-center">
              <span>{homeData.timingStatus == 1 ? 'Shop Opened' : 'Shop Closed'}</span>
              <Form.Check
                type="switch"
                id="shop-status"
                label=""
                checked={homeData.timingStatus == 1}
                onChange={(e) => changeShopAvilability(e.target.checked ? 1 : 0)}
                style={{
                  transform: 'scale(1.5)', // Increases overall size
                }}
              />

            </Card.Body>
          </Card>

          {/* <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">ORDERS</Card.Title>
              
              <Card.Text className="text-center text-muted">No Orders</Card.Text>
            </Card.Body>
          </Card> */}

          {homeData.OrderDetails?.length == 0 ? <>
            <p className='text-center'>----- No Orders -----</p>
          </> : currentOrders?.map((order) => (
            <>

              <Card key={order.OrderID} className="mb-4" style={{ margin: "40px" }}>
                {/* <Card.Body >
               
                <h5>Pre Booking</h5>
              </Card.Body> */}
                <Card.Body >
                  {order.OrderType == 2 ? <Row className="bg-dark rounded mb-3 p-4">
                    <Col>
                      <h5 style={{ color: '#3EB557' }} className=''>Pre Booking</h5>
                    </Col>
                    <Col className='text-end'>
                      <label className='text-white'>Date : {order.OrderPrebookingDates}</label><br />
                      <label className='text-white'>Time : {order.OrderPrebookingTimes}</label>
                    </Col>
                  </Row> : <></>}

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div>
                      <Card.Title className="text-success mb-0">{order.OrderRefNo}</Card.Title>
                      <small className="text-muted">
                        {order.OrderDate}
                      </small>
                    </div>
                    <Badge
                      bg={order.OrderStatus === 'Pending' ? 'warning' :
                        order.OrderStatus === 'Rejected' ? 'danger' : 'success'}
                    >
                      {order.OrderStatus}
                    </Badge>
                  </div>
                  <Card.Subtitle className="mb-2">Order Details</Card.Subtitle>
                  {order?.OrderedProducts.map((item, index) => (
                    <div key={index} className="mb-2">
                      <div className="d-flex justify-content-between">
                        <div>
                          <strong>{item.ProSProductName}</strong>
                          <br />
                          {/* <small className="text-muted">{item.ProSProductName}</small> */}
                        </div>
                        <div>
                          <Badge bg="light" text="dark" pill>
                            {item.OrderPQuantity}
                          </Badge>
                          <small> ₹ {item.OrderPShopprice}</small>
                        </div>

                      </div>
                      {item.addons && (
                        <div className="ms-3 mt-1">
                          {item.addons.map((custom, idx) => (
                            <div key={idx} className="d-flex justify-content-between">
                              <small>{custom.name}</small>
                              <small className="text-warning">{custom.value}</small>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}


                </Card.Body>
              </Card>
            </>
          ))
          }
             {totalPages > 1 && (
            <Pagination className="justify-content-center">
              <Pagination.First
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              />
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages)].map((_, index) => (
                <Pagination.Item
                  key={index + 1}
                  active={index + 1 === currentPage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
              <Pagination.Last
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          )}
        </Container>
      }

    </Container>
  )
}
