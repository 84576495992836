import './App.css';
import Login from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import { Header } from './components/Navbar';
import Orders from './pages/Orders';
import Register from './pages/Registeration/Register';
import AccountPage from './pages/account/Account';
import MenuList from './pages/account/MenuList';
import AddProduct from './pages/account/AddProduct';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLogin } from './redux/slice/LoginSlice';
import { setShopID } from './redux/slice/ShopSlice';
import Settings from './pages/account/Settings';
import FoodTiming from './pages/account/FoodTiming';
import ApprovalPage from './pages/Registeration/ApprovalPage';
import EditProduct from './pages/account/EditProduct';
import EditShopDetails from './pages/account/EditShopDetails';


function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const dispatch = useDispatch();


  // Check if user is logged in
  const isLogin = useSelector(state => state.login.isLogin);
  const log = JSON.parse(localStorage.getItem("7NMUser"));

  // Set login state based on log
  dispatch(setIsLogin(log ? true : false));

  // Redirect to login if log is false
  if (!log && location.pathname !== '/' && location.pathname !== '/register' && location.pathname !== '/approval-pending') {
    return <Navigate to="/" />;
  } 
  else if(log && location.pathname == '/' && location.pathname != '/register' ) {
    dispatch(setShopID(log))
    return <Navigate to="/home" />;
  }
  else{
    dispatch(setShopID(log))
  }

  return (
    <div>
      {location.pathname !== '/' && location.pathname !== '/register' && location.pathname !== '/approval-pending' && <Header />}
      {isLogin ? (
        <div>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/account" element={<AccountPage />} />
            <Route path="/account/menu-list" element={<MenuList />} />
            <Route path="/account/add-product" element={<AddProduct />} />
            <Route path="/account/edit-product/:id" element={<EditProduct />} />
            <Route path="/account/settings" element={<Settings />} />
            <Route path="/account/food-timing" element={<FoodTiming />} />
            <Route path="/account/edit-shop" element={<EditShopDetails />} />        
          </Routes>
        </div>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />   
          <Route path="/register" element={<Register />} /> 
          <Route path="/approval-pending" element={<ApprovalPage />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
