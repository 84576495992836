import React, { useEffect, useState } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ApprovalPage = () => {

    const styles = {

        button: {
            width: '15%',
            padding: '15px',
            backgroundColor: '#ff7f00',
            color: '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1em',
        },

    };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const BaseURL = process.env.REACT_APP_Base_URL;
    // const shopID = useSelector(state => state.shop.shopID)
    const shopID = typeof window !== 'undefined' ? localStorage.getItem('7NMUserRegiter') || '' : ''

    const handleRefresh = async () => {
        setLoading(true);
        setError(null); // Reset error state

        // Simulating an API call
        console.log("API function called");
        const formData = new FormData();
        formData.append('ShopID', shopID);

        // Replace with your actual API endpoint
        const response = await axios.post(BaseURL + '/RefreshApproveStatus', formData);

        // if (!response.ok) {
        //     throw new Error('Network response was not ok');
        // }

        const data = response.data;
        if (data.shopapprovestatus == 1) {
            localStorage.setItem('7NMUser',JSON.parse(shopID))
            localStorage.removeItem('7NMUserRegiter');
            navigate('/home')
        } else if (data.shopapprovestatus == 0) {
            setError("Sorry! Admin has Rejected your request.");
        } else {
            setError("Admin has not approved your request Yet, Please Wait for admin's approval");
        }

        console.log(data); // Process your data as needed
        setLoading(false);

    };

    useEffect(() => {
        handleRefresh()
    }, []);

    return (
        <Container
            className="d-flex flex-column justify-content-center align-items-center vh-100"
            style={{ textAlign: 'center' }}
        >
            <div style={{ fontSize: '50px', color: 'orange' }}>
                <span role="img" aria-label="Warning">⚠️</span>
            </div>
            <h2>Waiting For Approval</h2>
            {/* <p>Please wait</p> */}
            {error && <Alert variant="danger">{error}</Alert>}
            {loading ? <Button
                style={{
                    ...styles.button, // Spread the styles from the 'styles.button' object
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}
            >
                <div className="spinner-border text-white" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </Button> :
                <Button
                    onClick={handleRefresh}
                    variant="warning"
                    size="lg"
                    style={{ marginTop: '20px' }}
                >
                    REFRESH
                </Button>
            }
        </Container>
    );
};

export default ApprovalPage;
