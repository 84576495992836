import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
// import { BASE_URL } from '../../globe';

const initialState = {

    shopID: null,
    shopPhone: null,

}


export const ShopSlice = createSlice({
    name: 'Shop',
    initialState,
    reducers: {

        setShopID: (state, action) => {
            state.shopID = action.payload
        },
        setShopPhone: (state, action) => {
            state.shopPhone = action.payload
        },

    }
})

export const { setShopID, setShopPhone } = ShopSlice.actions

export default ShopSlice.reducer