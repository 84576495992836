import axios from 'axios';

const settingsApi = axios.create({
    baseURL: process.env.REACT_APP_Base_URL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const ViewSetting = async (data) => {
    try {
        const response = await settingsApi.post('/ViewSetting', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};


export const SettingUpdate = async (data) => {
    try {
        const response = await settingsApi.post('/SettingUpdate', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};


export const SettingShopAdd = async (data) => {
    try {
        const response = await settingsApi.post('/SettingShopAdd', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const FoodTimeAdd = async (data) => {
    try {
        const response = await settingsApi.post('/add_product_availability_time', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const ListFoodTimes = async (data) => {
    try {
        const response = await settingsApi.post('/ListShopProductTimes', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const SettingShopView = async (data) => {
    try {
        const response = await settingsApi.post('/SettingShopView', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const SettingShopDelete = async (data) => {
    try {
        const response = await settingsApi.post('/SettingShopDelete', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const SettingShopUpdate = async (data) => {
    try {
        const response = await settingsApi.post('/SettingShopUpdate', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const FoodTimeUpdate = async (data) => {
    try {
        const response = await settingsApi.post('/update_product_availability_time', data);

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch setting data. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};