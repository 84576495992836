import React, { useState } from 'react';
import { Form, Button, ProgressBar } from 'react-bootstrap';
import axios from 'axios'; // To make API calls
import './Register.css'; // Optional CSS for styling the steps
import { useDispatch, useSelector } from 'react-redux';
import { setRegStep } from '../../redux/slice/LoginSlice';
import ShopDetails from './ShopDetails';
import ServiceDetail from './ServiceDetail';
import { useNavigate } from 'react-router-dom';

function Register() {

  const styles = {
    button: {
      width: '100%',
      marginTop: '15px',
      backgroundColor: '#FFC007',
      color: '#ffffff',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '1em',
    },
  };

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // const [step, setStep] = useState(1);
  const regStep = useSelector(state => state.login.regStep)
  const shopPhone = useSelector(state => state.shop.shopPhone || JSON.parse(localStorage.getItem('7NMUPhone')));

  // const BaseURL = useSelector(state => state.login.BaseURL)
  const BaseURL = process.env.REACT_APP_Base_URL;
  console.log('reggg', regStep);

  const [ownerDetails, setOwnerDetails] = useState({
    name: '',
    email: '',
    phone: shopPhone,
  });
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // For phone input: prevent non-numeric characters, restrict first digit to 6-9, and limit input to 10 digits
    if (name === 'phone') {
      // Check if value is empty or matches the pattern for starting with 6-9 and has max 10 digits
      if (value === "" || (/^[6-9]\d{0,9}$/.test(value) && value.length <= 10)) {
        setOwnerDetails({ ...ownerDetails, [name]: value });
      }
    } else {
      setOwnerDetails({ ...ownerDetails, [name]: value });
    }
  };

  // Validate the form inputs
  const validateForm = () => {
    const errors = {};
    if (!ownerDetails.name) errors.name = 'Name is required';
    if (!ownerDetails.email) errors.email = 'Email is required';
    if (!/\S+@\S+\.\S+/.test(ownerDetails.email)) errors.email = 'Email is invalid';
    if (!ownerDetails.phone) errors.phone = 'Phone number is required';
    if (!/^\d{10}$/.test(ownerDetails.phone)) errors.phone = 'Phone number is invalid';
    return errors;
  };

  // Handle form submission
  const handleNextClick = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Call API here to verify the owner details
    try {
      setIsLoading(true)
      const response = await axios.post(BaseURL + '/AddProfileOwnerDetails', ownerDetails);
      if (response.data.status == true) {
        // Proceed to the next step if API succeeds
        setIsLoading(false)

        dispatch(setRegStep(regStep + 1));
      } else {
        setIsLoading(false)

        alert('API verification failed');
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error in API call', error);
      alert('Something went wrong with the API');
    }
  };

  return (
    <div className="register-form container">
      <h3>Create Account</h3>

      <ProgressBar now={regStep == 1 ? 0 : regStep == 2 ? 40 : regStep == 3 ? 76 : 0} />

      <div className="steps mt-3">
        <span className={`step ${regStep > 1 ? 'completed' : ''}`}>1</span> Owner Details
        <span className={`step ${regStep > 2 ? 'completed' : ''}`}>2</span> Shop Details
        <span className={`step ${regStep > 3 ? 'completed' : ''}`}>3</span> Service Details
      </div>

      {regStep == 1 && (
        <div className='d-flex justify-content-center'>
          <Form className="mt-4 w-50">
            <Form.Group controlId="name">
              <Form.Label>Name <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                name="name"
                value={ownerDetails.name}
                onChange={handleInputChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mt-3">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Your Mail"
                name="email"
                value={ownerDetails.email}
                onChange={handleInputChange}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="phone" className="mt-3">
              <Form.Label>Phone Number <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter Phone Number"
                name="phone"
                value={shopPhone}
                onChange={handleInputChange}
                isInvalid={!!errors.phone}
                // readOnly
                disabled
              />
              <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
            </Form.Group>

            <Button variant="warning" className="mt-4 w-100"
              onClick={handleNextClick} disabled={isLoading}>
              {isLoading && (
                <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              Next
            </Button>

          </Form>
        </div>

      )}
      {/* {regStep === 2 && (
        <div>
            <ShopDetails/>
        </div>
      )} */}
      {regStep == 2 && <ShopDetails />}
      {/* Step 2 and 3 can follow similar structure */}
      {regStep == 3 && <ServiceDetail />}
    </div>
  );
}

export default Register;
