import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLogin: false,
    regStep: 0,
    BaseURL : process.env.REACT_APP_Base_URL,
}

export const LoginSlice = createSlice({
    name: 'Login',
    initialState,
    reducers: {
        setIsLogin: (state, action) => {
            state.isLogin = action.payload
        },
        setRegStep: (state, action) => {          
            state.regStep = action.payload
        }
    }
})

export const { setIsLogin,setRegStep } = LoginSlice.actions

export default LoginSlice.reducer