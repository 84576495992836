import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';

const initialState = {

    currInstantPage: 1,
    currPreBookPage: 1,

}


export const OrderSlice = createSlice({
    name: 'Order',
    initialState,
    reducers: {

        setCurrInstantPage: (state, action) => {
            state.currInstantPage = action.payload
        },
        setCurrPreBookPage: (state, action) => {
            state.currPreBookPage = action.payload
        },


    }
})

export const { setCurrInstantPage, setCurrPreBookPage } = OrderSlice.actions

export default OrderSlice.reducer