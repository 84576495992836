import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Nav, Card, Badge, Button, Modal, Form, Pagination } from 'react-bootstrap';
import { Bell } from 'react-bootstrap-icons';
import { useMutation } from 'react-query';
import { MerchandChangeOrderStatus, merchantInstantOrders, merchantPreBookOrders } from '../utils/api';
import { useDispatch, useSelector } from 'react-redux';
import { CiFilter } from "react-icons/ci";
import 'react-datepicker/dist/react-datepicker.css';
import './Registeration/Register.css'

import DatePicker from 'react-datepicker';
import { setCurrInstantPage, setCurrPreBookPage } from '../redux/slice/OrderSlice';

export default function Orders() {

  
  const [activeTab, setActiveTab] = useState('instant');
  const BaseURL = process.env.REACT_APP_Base_URL;
  const [orders, setOrders] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [clearedFilter, setClearedFilter] = useState(false);
  const shopID = useSelector(state => state.shop.shopID);
  const currInstantPage = useSelector(state => state.orders.currInstantPage);
  const currPreBookPage = useSelector(state => state.orders.currPreBookPage);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();

  console.log({ orders });


  // Handle radio button selection
  const handleRadioChange = (filterName) => {
    setSelectedStatus(filterName);
  };

  // Clear all filters
  const handleClearAll = () => {
    setSelectedStatus('');
    setSelectedDate('')
    setClearedFilter(!clearedFilter)
    // activeTab == 'instant' ? fetchOrders() : fetchProBookOrders()
  };

  const mutation = useMutation(merchantInstantOrders, {
    onSuccess: (res) => {
      console.log({ res });

      setOrders(res.Data)
    },
    onError: (error) => {
      console.error(error);
    },
  });
  const preBook = useMutation(merchantPreBookOrders, {
    onSuccess: (res) => {
      console.log({ res });

      setOrders(res.Data)
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const changeOrderStatus = useMutation(MerchandChangeOrderStatus, {
    onSuccess: (res) => {
      console.log({ res });
      activeTab == 'instant' ? fetchOrders() : fetchProBookOrders()
      // setOrders(res.Data.OrderDetails)
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const fetchOrders = async () => {
    const date = formatDate(selectedDate)
    try {
      setFilterModal(false)
      const formData = new FormData();
      formData.append('ShopID', shopID); // Use the Redux store value
      formData.append('currentPage', currInstantPage);
      formData.append('date', date);
      formData.append('status', selectedStatus);
      await mutation.mutateAsync(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchProBookOrders = async () => {
    const date = formatDate(selectedDate)
    try {
      const formData = new FormData();
      formData.append('ShopID', shopID); // Use the Redux store value
      formData.append('currentPage', currPreBookPage);
      formData.append('date', date);
      formData.append('status', selectedStatus);
      await preBook.mutateAsync(formData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOrderStatus = async (status, orderId) => {
    try {
      const formData = new FormData();
      formData.append('shopId', shopID); // Use the Redux store value
      formData.append('Status', status);
      formData.append('OrderID', orderId);

      await changeOrderStatus.mutateAsync(formData);
    } catch (err) {
      console.error(err);
    }
  };
  function formatDate(date) {
    if (date == '') {
      return ''
    }
    const d = new Date(date);

    // Extract the year, month, and day
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so +1
    const day = String(d.getDate()).padStart(2, '0');

    // Return in the format: YYYY-MM-DD
    return `${year}-${month}-${day}`;
  }

  const calculateTotalPages = () => {
    if (activeTab === 'instant') {
      setTotalPages(Math.ceil(orders.instantCount / 10));
      setCurrentPage(currInstantPage)
    } else {
      setTotalPages(Math.ceil(orders.prebookingCount / 10));
      setCurrentPage(currPreBookPage)
    }
  };

  useEffect(() => {
    activeTab == 'instant' ? fetchOrders() : fetchProBookOrders()
  }, [currInstantPage, currPreBookPage, activeTab, clearedFilter]);

  useEffect(() => {
    calculateTotalPages();
  }, [orders]);


  return (
    <Container fluid className="min-vh-100">


      <Container className="mt-4">
        <p className='text-end me-4' onClick={() => setFilterModal(true)}><CiFilter size={30} />
        </p>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link
              active={activeTab === 'instant'}
              onClick={() => setActiveTab('instant')}
              className="text-warning"
            >
              Instant Orders <Badge bg="warning">{orders?.instantCount}</Badge>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={activeTab === 'pre-booking'}
              onClick={() => setActiveTab('pre-booking')}
              className="text-warning"
            >
              Pre-Booking Orders <Badge bg="warning">{orders?.prebookingCount}</Badge>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        {mutation.isLoading || preBook.isLoading ? <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
        }}>
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div> : orders.OrderDetails && Array.isArray(orders.OrderDetails) && orders.OrderDetails.length > 0 ? orders.OrderDetails.map((order) => (
          <>

            <Card key={order.OrderID} className="mb-4" style={{ margin: "40px" }}>
              {/* <Card.Body >
               
                <h5>Pre Booking</h5>
              </Card.Body> */}
              <Card.Body >
                {activeTab == 'pre-booking' ? <Row className="bg-dark rounded mb-3 p-4">
                  <Col>
                    <h5 style={{ color: '#3EB557' }} className=''>Pre Booking</h5>
                  </Col>
                  <Col className='text-end'>
                    <label className='text-white'>Date : {order.OrderPrebookingDates}</label><br />
                    <label className='text-white'>Time : {order.OrderPrebookingTimes}</label>
                  </Col>
                </Row> : <></>}

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <Card.Title className="text-success mb-0">{order.OrderRefNo}</Card.Title>
                    <small className="text-muted">
                      {order.OrderDate}
                    </small>
                  </div>
                  <Badge
                    bg={order.OrderStatus === 'Pending' ? 'warning' :
                      order.OrderStatus === 'Rejected' ? 'danger' : 'success'}
                  >
                    {order.OrderStatus}
                  </Badge>
                </div>
                <Card.Subtitle className="mb-2">Order Details</Card.Subtitle>
                {order?.OrderedProducts.map((item, index) => (
                  <div key={index} className="mb-2">
                    <div className="d-flex justify-content-between">
                      <div>
                        <strong>{item.ProSProductName}</strong>
                        <br />
                        {/* <small className="text-muted">{item.ProSProductName}</small> */}
                      </div>
                      <div>
                        <Badge bg="light" text="dark" pill>
                          {item.OrderPQuantity}
                        </Badge>
                        <small> ₹ {item.OrderPShopprice}</small>
                      </div>

                    </div>
                    {item.addons && (
                      <div className="ms-3 mt-1">
                        {item.addons.map((custom, idx) => (
                          <div key={idx} className="d-flex justify-content-between">
                            <small>{custom.name}</small>
                            <small className="text-warning">{custom.value}</small>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                {order.OrderStatus === 'Pending' && (
                  <div className="d-flex justify-content-end mt-3">
                    <Button variant="outline-danger" className="w-25 me-2" onClick={() => {
                      if (confirm(`Are you sure you want to reject ${order.OrderRefNo}?`)) {
                        handleOrderStatus('cancelled', order.OrderID);
                      }
                    }}>Reject</Button>
                    <Button variant="warning" className="w-25 ms-2" onClick={() => {
                      if (confirm(`Are you sure you want to accept ${order.OrderRefNo}?`)) {
                        handleOrderStatus('accepted', order.OrderID);
                      }
                    }}>Accept</Button>
                  </div>
                )}
                {order.OrderStatus === 'Accepted' && (
                  <div className="d-flex justify-content-end mt-3">
                    <Button variant="warning" className="w-25" onClick={() => {
                      if (confirm(`Are you sure you want to mark this ${order.OrderRefNo} as completed?`)) {
                        handleOrderStatus('delivered', order.OrderID);
                      }
                    }}>Mark as Completed</Button>
                  </div>
                )}

              </Card.Body>
            </Card>
          </>
        ))
          : <>
            <h5 className='text-center mt-5'>No order history</h5>
          </>
        }
        {totalPages > 1 && orders.OrderDetails && Array.isArray(orders.OrderDetails) && orders.OrderDetails.length > 0 ?  <Pagination className="justify-content-center mt-5">
          <Pagination.Prev
            onClick={() => {
              activeTab === 'instant' ? dispatch(setCurrInstantPage(currInstantPage - 1)) : dispatch(setCurrPreBookPage(currPreBookPage - 1));
              setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1} style={{ color: '#ffc107' }}>
            Previous
          </Pagination.Prev>
          {[...Array(totalPages)].map((_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => {
                activeTab === 'instant' ? dispatch(setCurrInstantPage(i + 1)) : dispatch(setCurrPreBookPage(i + 1));
                setCurrentPage(i + 1);
              }}
              style={{ backgroundColor: '#ffc107', borderColor: '#ffc107' }}>
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => {
              activeTab === 'instant' ? dispatch(setCurrInstantPage(currInstantPage + 1)) : dispatch(setCurrPreBookPage(currPreBookPage + 1));
              setCurrentPage(currentPage + 1);
            }}
            disabled={currentPage === totalPages}>
            Next
          </Pagination.Next>
        </Pagination> : <></>}



      </Container>

      <Modal show={filterModal} onHide={() => setFilterModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button variant="close" onClick={() => setFilterModal(false)} aria-label="Close">
            <span aria-hidden="true"></span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          {/* Date Picker */}
          <Form.Group controlId="filterDate">
            <Form.Label>Date</Form.Label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd-MM-yyyy"
              className="form-control ms-2"
              showPopperArrow={false}
            />
          </Form.Group>

          {/* Filter Radio Buttons */}
          <Form.Group controlId="filterType">
            <Form.Label>Type</Form.Label>
            <div>
              <Form.Check
                type="radio"
                label="Accepted"
                name="filterType"
                id="merAccepted"
                value="merAccepted"
                checked={selectedStatus === 'merAccepted'}
                onChange={() => handleRadioChange('merAccepted')}
              />
              <Form.Check
                type="radio"
                label="Delivered"
                name="filterType"
                id="delivered"
                value="delivered"
                checked={selectedStatus === 'delivered'}
                onChange={() => handleRadioChange('delivered')}
              />
              <Form.Check
                type="radio"
                label="Cancelled"
                name="filterType"
                id="cancelled"
                value="cancelled"
                checked={selectedStatus === 'cancelled'}
                onChange={() => handleRadioChange('cancelled')}
              />
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {/* Clear all and Filter buttons */}
          <Button variant="secondary" onClick={() => {

            setSelectedDate('');
            handleClearAll()
          }}>
            Clear all
          </Button>
          <Button variant="warning" onClick={() => { activeTab == 'instant' ? fetchOrders() : fetchProBookOrders(); setFilterModal(false) }}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>


    </Container>
  );
}
