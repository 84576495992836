import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_Base_URL,
    // timeout: 15000,
    headers: {
        'Content-Type': 'application/json',
    },
});


export const merchantProductList = async (shopId, categoryId = 0, currentPage = 1, searchTerm = "") => {
    try {
        const formData = new FormData();
        formData.append('ShopID', shopId);
        formData.append('CategoryID', categoryId.toString());
        formData.append('currentPage', currentPage);
        formData.append('search', searchTerm); // Include search term

        const response = await api.post(
            '/MerchandProductList',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data.Data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to fetch product list. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};



export const merchantEnableProduct = async (shopId, categoryId, productId) => {
    try {
        console.log("API")
        const formData = new FormData();
        formData.append('ShopID', shopId);
        formData.append('catid', categoryId);
        formData.append('ProMID', productId);

        const response = await api.post(
            '/MerchandEnableProduct',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to request password reset. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const merchandChangeAvailableStatus = async (shopId, categoryId, productId) => {
    try {
        console.log("API")
        const formData = new FormData();
        formData.append('ShopID', shopId);
        formData.append('categoryId', categoryId);
        formData.append('ProMID', productId);

        const response = await api.post(
            '/MerchandChangeAvailableStatus',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to request password reset. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const merchantDeleteProduct = async (shopId, categoryId, productId) => {
    try {
        console.log("API")
        const formData = new FormData();
        formData.append('shopId', shopId);
        formData.append('categoryId', categoryId);
        formData.append('productId', productId);

        const response = await api.post(
            '/merchantDeleteProduct',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to request password reset. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const merchantAddProducts = async (data) => {
    try {
        const response = await api.post('/MerchantAddProduct');

        return response.data;
    } catch (error) {
        if (error) {
            throw new Error(
                error.response?.data ||
                'Failed to request password reset. Please try again.'
            );
        } else {
            throw new Error('An unexpected error occurred. Please try again.');
        }
    }
};

export const merchantAddCategory = async (categoryData) => {
    try {
        const response = await api.post('/MerchantDataCategoryAdd', categoryData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to add category. Please try again.'
        );
    }
};


export const merchantInstantOrders = async (orderData) => {
    try {
        const response = await api.post('/MerchandShopOrders', orderData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to List Orders. Please try again.'
        );
    }
};

export const merchantGetCategories = async (shopId) => {
    try {
        const formData = new FormData();
        formData.append('shopid', shopId);

        const response = await api.post(
            '/MerchantDataCategories',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get categories. Please try again.'
        );
    }
};

export const merchantGetSubCategories = async (catID) => {
    try {

        console.log("api", catID)
        const formData = new FormData();
        formData.append('CatID', catID);

        const response = await api.post(
            '/MerchantDataSubCategories',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data.Data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get subcategories. Please try again.'
        );
    }
};

export const merchantPreBookOrders = async (orderData) => {
    try {
        const response = await api.post('/MerchantPreBookingShopOrders', orderData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to List Orders. Please try again.'
        );
    }
};

export const getShopTiming = async (shopId) => {
    try {
        const formData = new FormData();
        formData.append('ShopID', shopId);

        const response = await api.post(
            '/ListShopProductTimes',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get categories. Please try again.'
        );
    }
};

export const MerchandChangeOrderStatus = async (orderData) => {
    try {
        const response = await api.post('/MerchandChangeOrderStatus', orderData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to List Orders. Please try again.'
        );
    }
};


export const MerchantHome = async (shopData) => {
    try {
        const response = await api.post('/MerchandHome', shopData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to List Home Details. Please try again.'
        );
    }
};

export const MerchandShopDetail = async (shopData) => {
    try {
        const response = await api.post('/MerchandShopDetail', shopData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to get Shop Details. Please try again.'
        );
    }
};

export const addProduct = async (data) => {
    try {

        const subcategoryString = Array.isArray(data.subcategory) ? data.subcategory.join(',') : '';
        const timeString = Array.isArray(data.PAvailTimeIDs) ? data.PAvailTimeIDs.join(',') : '';

        console.log("API Triggered")
        const formData = new FormData();

        formData.append('shopid', data.shopid);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);

        // formData.append('subcategory', JSON.stringify(data.subcategory)); // Assume it's an array
        formData.append('subcategory', subcategoryString);
        formData.append('productOrderType', data.productOrderType);
        formData.append('price', data.price);

        formData.append('salePrice', data.salePrice);
        // formData.append('PAvailTimeIDs', JSON.stringify(data.PAvailTimeIDs)); 
        formData.append('PAvailTimeIDs', timeString);
        formData.append('ProSIsCustomized', data.ProSIsCustomized);
        formData.append('addons', JSON.stringify(data.addons)); // Assume it's an array of objects
        formData.append('ProSPackingAmt', data.ProSPackingAmt || 0);
        formData.append('units', JSON.stringify(data.units)); // Assume it's an array
        formData.append('Unitval', JSON.stringify(data.Unitval)); // Assume it's an array
        formData.append('GstID', data.GstID);
        formData.append('type', data.type);

        if (data.image) {
            formData.append('image', data.image);
        }

        formData.forEach((value, key) => {
            console.log(`Key: ${key}, Type: ${typeof value}, Value:`, value);
        });


        const response = await api.post(
            '/MerchantAddProductWeb',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data.Data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to Add Product. Please try again.'
        );
    }
};

export const editProduct = async (data) => {
    try {

        const subcategoryString = Array.isArray(data.subcategory) ? data.subcategory.join(',') : '';
        const timeString = Array.isArray(data.PAvailTimeIDs) ? data.PAvailTimeIDs.join(',') : '';

        console.log("API Triggered")
        const formData = new FormData();

        formData.append('shopid', data.shopid);
        formData.append('id', data.id);
        formData.append('name', data.name);
        formData.append('description', data.description);
        formData.append('category', data.category);

        // formData.append('subcategory', JSON.stringify(data.subcategory)); // Assume it's an array
        formData.append('subcategory', subcategoryString);
        formData.append('productOrderType', data.productOrderType);
        formData.append('price', data.price);

        formData.append('salePrice', data.salePrice);
        // formData.append('PAvailTimeIDs', JSON.stringify(data.PAvailTimeIDs)); 
        formData.append('PAvailTimeIDs', timeString);
        formData.append('ProSIsCustomized', data.ProSIsCustomized);
        formData.append('addons', JSON.stringify(data.addons)); // Assume it's an array of objects
        formData.append('ProSPackingAmt', data.ProSPackingAmt || 0);
        formData.append('units', JSON.stringify(data.units)); // Assume it's an array
        formData.append('Unitval', JSON.stringify(data.Unitval)); // Assume it's an array
        formData.append('GstID', data.GstID);
        formData.append('type', data.type);

        if (data.image) {
            formData.append('image', data.image);
        }

        formData.forEach((value, key) => {
            console.log(`Key: ${key}, Type: ${typeof value}, Value:`, value);
        });

        const response = await api.post(
            '/MerchantUpdateProductWeb',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data.Data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get categories. Please try again.'
        );
    }
};

export const getProduct = async (id, shopId) => {
    try {
        const formData = new FormData();
        formData.append('shopId', shopId);
        formData.append('id', id);

        const response = await api.post(
            '/MerchantEditProductWeb',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get categories. Please try again.'
        );
    }
};

export const getShopData = async (shopId) => {
    try {
        const formData = new FormData();
        formData.append('ShopID', shopId);

        const response = await api.post(
            '/ViewShopData',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message || 'Failed to get shop data. Please try again.'
        );
    }
};

export const UpdateShopData = async (data) => {
    try {
        const response = await api.post('/UpdateShopData', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to Update Shop Details. Please try again.'
        );
    }
};

export const getShopTypes = async () => {
    try {
        const response = await api.post('/GetShopTypes');
        return response.data.Data;
    } catch (error) {
        throw new Error(
            error.response?.data?.message ||
            'Failed to get Shop Details. Please try again.'
        );
    }
};