import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, Accordion, Card } from 'react-bootstrap';
import Select from 'react-select'; // Assuming you're using react-select
import { useNavigate } from 'react-router-dom';

const ServiceDetail = () => {

    const styles = {
        button: {
            width: '100%',
            marginTop: '15px',
            backgroundColor: '#FFC007',
            color: '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1em',
        },
    };

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSubCategories, setSelectedSubCategories] = useState({});
    const [status, setStatus] = useState('Active');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const BaseURL = process.env.REACT_APP_Base_URL;
    // const shopID = useSelector(state => state.shop.shopID);
    const shopID = typeof window !== 'undefined' ? localStorage.getItem('7NMUserRegiter') || '' : ''

    const shopPhone = useSelector(state => state.shop.shopPhone || JSON.parse(localStorage.getItem('7NMUPhone')));
    const navigate = useNavigate();


    useEffect(() => {
        const formData = new FormData();
        formData.append('shopid', shopID);
        axios.post(BaseURL + '/MerchantDataCategories', formData)
            .then((response) => {
                setCategories(response.data.Data);
            })
            .catch((error) => {
                console.error("Error fetching categories", error);
            });
    }, []);

    const categoryOptions = categories.map(category => ({
        value: category.CatID,
        label: category.CatName,
    }));

    const fetchSubCategories = async (categoryIds) => {

        const formData = new FormData();
        formData.append('CatID', categoryIds.join(','));
        await axios.post(BaseURL + `/MerchantDataSubCategories`, formData)
            .then((response) => {
                setSubCategories(response.data.Data);
            })
            .catch((error) => {
                console.error("Error fetching subcategories", error);
            });
    };

    const handleCategoryChange = (selectedOptions) => {
        setSelectedCategories(selectedOptions);
        fetchSubCategories(selectedOptions.map(option => option.value));
    };

    const handleSubCategoryChange = (categoryId, subCategoryId, checked) => {
        if (!selectedSubCategories[categoryId]) {
            selectedSubCategories[categoryId] = [];
        }

        if (checked) {
            selectedSubCategories[categoryId].push(subCategoryId);
        } else {
            selectedSubCategories[categoryId] = selectedSubCategories[categoryId].filter(id => id !== subCategoryId);
        }

        setSelectedSubCategories({ ...selectedSubCategories });
    };

    const validate = () => {
        let tempErrors = {};
        if (selectedCategories.length === 0) {
            tempErrors.category = 'Please select at least one category';
        }
        if (Object.keys(selectedSubCategories).length === 0 || Object.values(selectedSubCategories).every(arr => arr.length === 0)) {
            tempErrors.subCategory = 'Please select at least one subcategory';
        }
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = () => {
        if (validate()) {
            setIsLoading(true)
            const formData = new FormData();

            // Prepare category IDs
            const categoryIds = selectedCategories.map(category => category.value).join(',');
            formData.append('catid', categoryIds);

            // Prepare phone numbers (assuming they're stored in subCategories)

            formData.append('phone', shopPhone);

            // Prepare subcategory IDs
            const subCategoryIds = Object.entries(selectedSubCategories)
                .flatMap(([categoryId, selectedIds]) =>
                    selectedIds.map(id => `${categoryId},${id}`)
                )
                .join(',');
            formData.append('subcatid', subCategoryIds);

            // Append status
            formData.append('status', status === 'Active' ? 1 : 0);

            // Send the POST request
            axios.post(`${BaseURL}/AddProfileServiceDetails`, formData)
                .then(response => {
                    setIsLoading(false)
                    navigate('/approval-pending');
                    // localStorage.removeItem('7NMUserRegiter')

                    console.log('Profile completed successfully:', response.data);
                    // Handle success (e.g., show a success message, redirect to another page)
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error('Error completing profile:', error);
                    // Handle error (e.g., show an error message)
                });
        }
    };

    return (
        <Container className="mt-4">
            <h2>Create Account</h2>
            <Row className='d-flex justify-content-center'>
                <Col md={6}>
                    <Form.Group controlId="formBasicSelectMultiple">
                        <Form.Label>Categories</Form.Label>
                        <Select
                            isMulti
                            options={categoryOptions}
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                        />
                        {errors.category && <div className="text-danger mt-2">{errors.category}</div>}
                    </Form.Group>

                    {subCategories.length > 0 && (
                        <Accordion defaultActiveKey="0">
                            {subCategories.map((category, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header>{category.CategoryName}</Accordion.Header>
                                    <Accordion.Body>
                                        {category.Data.map(subCategory => (
                                            <Form.Check
                                                key={subCategory.CatID}
                                                type="checkbox"
                                                label={subCategory.CatName}
                                                value={subCategory.CatID}
                                                onChange={(e) => handleSubCategoryChange(category.CategoryID, subCategory.CatID, e.target.checked)}
                                                checked={selectedSubCategories[category.CategoryID]?.includes(subCategory.CatID)}
                                            />
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    )}

                    {errors.subCategory && <div className="text-danger mt-2">{errors.subCategory}</div>}

                    <Form.Group controlId="formBasicSelectStatus">
                        <Form.Label>Status</Form.Label>
                        <Form.Select value={status} onChange={(e) => setStatus(e.target.value)}>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </Form.Select>
                    </Form.Group>

                    <div className='text-center'>
                        <Button variant="warning mt-3" onClick={handleSubmit} disabled={isLoading}>
                            {isLoading && (
                                <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                            Complete Profile
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceDetail;
