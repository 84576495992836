import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './Register.css'; // Optional styling
import MapModal from '../../components/MapModal';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import { useDispatch, useSelector } from 'react-redux';
import { setRegStep } from '../../redux/slice/LoginSlice';
import TimePicker from 'react-time-picker';

function ShopDetails() {

    const styles = {
        button: {
            width: '100%',
            marginTop: '15px',
            backgroundColor: '#FFC007',
            color: '#ffffff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1em',
        },
    };

    const dispatch = useDispatch();
    const regStep = useSelector(state => state.login.regStep)

    const shopPhone = useSelector(state => state.shop.shopPhone || JSON.parse(localStorage.getItem('7NMUPhone')));
    const [isLoading, setIsLoading] = useState(false);

    const [shopDetails, setShopDetails] = useState({
        shopname: '',
        shoptype: '',
        address: '',
        mapUrl: '',
        shoprating: '',
        openingTime: '',
        closingTime: '',
        preBookingAvailable: false,
        shopgstdocument: { foodsafety: '', getCertificate: '' },
        latitude: null,
        longitude: null,
        phone: shopPhone,
        shopaccount: '',
        ShopIFSC: '',
        ShopBankName: '',
        shopimage: '',
        shopgst: ''
    });

    console.log({ shopDetails });


    const BaseURL = process.env.REACT_APP_Base_URL;
    const [errors, setErrors] = useState({});
    const [shopTypes, setShopTypes] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [latLng, setLatLng] = useState({
        lat: '8.179244',
        lng: '77.412116',
    }); // To store the selected lat/lng coordinates
    const [submitted, setSubmitted] = useState(false); // State to check if the form was submitted

    const handleOpenMap = () => setShowMap(true);
    const handleCloseMap = () => setShowMap(false);

    const onSelectLocation = (newLatLng) => {
        setLatLng(newLatLng);
        setShopDetails({
            ...shopDetails,
            latitude: newLatLng.lat,
            longitude: newLatLng.lng,
        });
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setShopDetails({ ...shopDetails, [name]: checked });
        } else if (name === 'shoprating') {
            // Allow only numbers between 0 and 5
            const newValue = value === "" ? "" : Math.max(0, Math.min(5, Number(value)));
            setShopDetails({ ...shopDetails, [name]: newValue });
        } else {
            setShopDetails({ ...shopDetails, [name]: value });
        }
    };

    const getTypes = async () => {
        const response = await axios.post(BaseURL + '/GetShopTypes');
        if (response.data.status === true) {
            setShopTypes(response.data.Data);
        } else {
            alert('API verification failed');
        }
    };

    const handleSubmit = async () => {
        const valid = validateForm();
        setSubmitted(true); // Mark form as submitted to display errors
        if (valid) {
            setIsLoading(true)

            const formData = new FormData();

            // Add each field individually
            formData.append('shopname', shopDetails.shopname);
            formData.append('shoptype', shopDetails.shoptype);
            formData.append('address', shopDetails.address);
            formData.append('mapUrl', shopDetails.mapUrl);
            formData.append('shoprating', shopDetails.shoprating);
            formData.append('openingTime', shopDetails.openingTime);
            formData.append('closingTime', shopDetails.closingTime);
            formData.append('preBookingAvailable', shopDetails.preBookingAvailable ? 1 : 0); // Boolean values should be converted to strings
            formData.append('foodsafety', shopDetails.shopgstdocument.foodsafety);
            formData.append('getCertificate', shopDetails.shopgstdocument.getCertificate);
            formData.append('latitude', shopDetails.latitude);
            formData.append('longitude', shopDetails.longitude);
            formData.append('phone', shopDetails.phone);
            formData.append('shopaccount', shopDetails.shopaccount);
            formData.append('ShopIFSC', shopDetails.ShopIFSC);
            formData.append('ShopBankName', shopDetails.ShopBankName);

            // If shopimage is a file, append it properly
            if (shopDetails.shopimage) {
                formData.append('shopimage', shopDetails.shopimage);
            }

            formData.append('shopgst', shopDetails.shopgst);


            console.log('in', { shopDetails });
            const response = await axios.post(BaseURL + '/AddProfileShopDetails', formData);
            if (response.data.status === true) {
                setIsLoading(false)
                dispatch(setRegStep(regStep + 1));
                setShopTypes(response.data.Data);
            } else {
                setIsLoading(false)
                alert('API verification failed');
            }

        }
    };

    // Validate form inputs
    const validateForm = () => {
        const newErrors = {};
        if (!shopDetails.shopname) newErrors.shopname = 'Shop Name is required';
        if (!shopDetails.shoptype) newErrors.shoptype = 'Shop Type is required';
        if (!shopDetails.address) newErrors.address = 'Address is required';
        if (!shopDetails.shoprating || isNaN(shopDetails.shoprating) || shopDetails.shoprating < 0 || shopDetails.shoprating > 5) {
            newErrors.shoprating = 'Rating should be a number between 0 and 5';
        }
        if (!shopDetails.openingTime) newErrors.openingTime = 'Opening time is required';
        if (!shopDetails.closingTime) newErrors.closingTime = 'Closing time is required';
        if (shopDetails.latitude == '' || !shopDetails.longitude) newErrors.mapUrl = 'Location is required. Please open the map to select the location';
        if (!shopDetails.shopaccount) newErrors.shopaccount = 'Shop Account is required';
        if (!shopDetails.ShopIFSC) newErrors.ShopIFSC = 'IFSC is required';
        if (!shopDetails.ShopBankName) newErrors.ShopBankName = 'Bank Name is required';
        // if (!shopDetails.shopimage) newErrors.shopimage = 'Shop Image is required';
        if (!shopDetails.shopgstdocument.foodsafety) newErrors.rename = 'Food Saftey document is required';
        if (!shopDetails.shopgstdocument.getCertificate) newErrors.getCertificate = 'GST Certificate is required';
        if (!shopDetails.shopgst) newErrors.shopgst = 'GST Number is required';
        setErrors(newErrors);
        const formIsValid = Object.keys(newErrors).length === 0;
        setIsFormValid(formIsValid);
        console.log({ newErrors })
        return formIsValid;
    };

    // File upload handler
    const handleFileUpload = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            setShopDetails((prevDetails) => ({
                ...prevDetails,
                shopgstdocument: {
                    ...prevDetails.shopgstdocument,
                    [name]: files[0] // Save the file name (or file object)
                }
            }));
        }
    };

    const handleImageUpload = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            setShopDetails((prevDetails) => ({
                ...prevDetails,
                [name]: files[0]  // Save the file name (or file object)
            }));
        }
    };

    useEffect(() => {
        getTypes();
    }, []);

    const formatTime = (time) => {
        const [hours, minutes] = time.split(":");
        let period = 'AM';
        let formattedHours = parseInt(hours, 10);

        if (formattedHours >= 12) {
            period = 'PM';
            if (formattedHours > 12) {
                formattedHours -= 12;
            }
        } else if (formattedHours === 0) {
            formattedHours = 12;
        }

        return `${formattedHours}:${minutes} ${period}`;
    };
    
    return (
        <div className="shop-details-form container">
            <h3>Shop Details</h3>
            <Form className='mt-4'>
                <Row>
                    <Col md={6}>
                        {/* Shop Name */}
                        <Form.Group controlId="shopname">
                            <Form.Label>Shop Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Shop Name"
                                name="shopname"
                                value={shopDetails.shopname}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.shopname}
                            />
                            <Form.Control.Feedback type="invalid">{errors.shopname}</Form.Control.Feedback>
                        </Form.Group>

                        {/* Shop Type */}
                        <Form.Group controlId="shoptype" className="mt-3">
                            <Form.Label>Shop Type <span className="text-danger">*</span></Form.Label>
                            <Form.Select
                                name="shoptype"
                                value={shopDetails.shoptype}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.shoptype}
                            >
                                <option value="">Select Shop Type</option>
                                {shopTypes.map((type) => (
                                    <option key={type.ShopTypeID} value={type.ShopTypeName}>
                                        {type.ShopTypeName}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {errors.shoptype}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* Address */}
                        <Form.Group controlId="address" className="mt-3">
                            <Form.Label>Address <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Address"
                                name="address"
                                value={shopDetails.address}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.address}
                            />
                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                        </Form.Group>

                        {/* Shop Account */}
                        <Form.Group controlId="shopaccount" className="mt-3">
                            <Form.Label>Shop Account <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Shop Account"
                                name="shopaccount"
                                value={shopDetails.shopaccount}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.shopaccount}
                            />
                            <Form.Control.Feedback type="invalid">{errors.shopaccount}</Form.Control.Feedback>
                        </Form.Group>

                        {/* IFSC Code */}
                        <Form.Group controlId="ShopIFSC" className="mt-3">
                            <Form.Label>IFSC Code <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter IFSC"
                                name="ShopIFSC"
                                value={shopDetails.ShopIFSC}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.ShopIFSC}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ShopIFSC}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="shopgst" className="mt-3">
                            <Form.Label>Shop GST <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter GST Number"
                                name="shopgst"
                                value={shopDetails.shopgst}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.shopgst}
                            />
                            <Form.Control.Feedback type="invalid">{errors.shopgst}</Form.Control.Feedback>
                        </Form.Group>
                        {/* Prebooking */}
                        <Form.Group controlId="preBookingAvailable" className="mt-3">
                            <Form.Check
                                type="checkbox"
                                label="Prebooking"
                                name="preBookingAvailable"
                                checked={shopDetails.preBookingAvailable}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="documentsCertificate" className="mt-3">
                            <Form.Label>Get Certificate <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="file"
                                name="getCertificate"
                                onChange={handleFileUpload}
                                isInvalid={submitted && !!errors.getCertificate}
                            />
                            <Form.Control.Feedback type="invalid">{errors.getCertificate}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        {/* Bank Name */}
                        <Form.Group controlId="ShopBankName" className="mt-3">
                            <Form.Label>Bank Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Bank Name"
                                name="ShopBankName"
                                value={shopDetails.ShopBankName}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.ShopBankName}
                            />
                            <Form.Control.Feedback type="invalid">{errors.ShopBankName}</Form.Control.Feedback>
                        </Form.Group>

                        {/* Map URL */}
                        <Form.Group controlId="mapUrl" className="mt-3">
                            <Form.Label>Location on Map <span className="text-danger me-2">*</span></Form.Label>
                            <Button variant="secondary" onClick={handleOpenMap}>Open Map</Button>

                            {errors.mapUrl && (
                                <div className="invalid-feedback d-block">
                                    {errors.mapUrl}
                                </div>
                            )}
                        </Form.Group>

                        {/* Shop Image */}
                        <Form.Group controlId="shopimage" className="mt-3">
                            <Form.Label>Shop Image <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="file"
                                name="shopimage"
                                onChange={handleImageUpload}
                                isInvalid={submitted && !!errors.shopimage}
                            />
                            <Form.Control.Feedback type="invalid">{errors.shopimage}</Form.Control.Feedback>
                        </Form.Group>

                        {/* Rating */}
                        <Form.Group controlId="shoprating" className="mt-3">
                            <Form.Label>Rating <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="number"
                                name="shoprating"
                                value={shopDetails.shoprating}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.shoprating}
                                placeholder="Enter Rating (0-5)"
                            />
                            <Form.Control.Feedback type="invalid">{errors.shoprating}</Form.Control.Feedback>
                        </Form.Group>

                        {/* Opening Time */}
                        <Form.Group controlId="openingTime" className="mt-3">
                            <Form.Label>Opening Time <span className="text-danger me-2">*</span></Form.Label>

                            <TimePicker
                                onChange={(value) => setShopDetails({ ...shopDetails, openingTime: value })}
                                name="openingTime"
                                value={shopDetails.openingTime}
                                format="hh:mm a" // 12-hour format with AM/PM
                                clearIcon={null} // You can hide the clear button if you prefer
                                disableClock={true} // Optionally disable the clock icon
                                isInvalid={submitted && !!errors.openingTime}
                            />
                            {/* <Form.Control.Feedback type="invalid">{errors.openingTime}</Form.Control.Feedback> */}
                            {errors.openingTime && (
                                <div className="invalid-feedback d-block">
                                    {errors.openingTime}
                                </div>
                            )}
                        </Form.Group>

                        {/* Closing Time */}
                        <Form.Group controlId="closingTime" className="mt-3">
                            <Form.Label>Closing Time <span className="text-danger me-2">*</span></Form.Label>
                            {/* <Form.Control
                                type="time"
                                name="closingTime"
                                value={shopDetails.closingTime}
                                onChange={handleInputChange}
                                isInvalid={submitted && !!errors.closingTime}
                            /> */}
                            <TimePicker
                                onChange={(value) => setShopDetails({ ...shopDetails, closingTime: value })}
                                name="closingTime"
                                value={shopDetails.closingTime}
                                format="hh:mm a" // 12-hour format with AM/PM
                                clearIcon={null} // You can hide the clear button if you prefer
                                disableClock={true} // Optionally disable the clock icon
                                isInvalid={submitted && !!errors.openingTime}
                            />
                            {/* <Form.Control.Feedback type="invalid">{errors.closingTime}</Form.Control.Feedback> */}
                            {errors.closingTime && (
                                <div className="invalid-feedback d-block">
                                    {errors.closingTime}
                                </div>
                            )}
                        </Form.Group>


                        {/* Documents Upload */}
                        <Form.Group controlId="documentsRename" className="mt-3">
                            <Form.Label>Food Saftey Document <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="file"
                                name="foodsafety"
                                onChange={handleFileUpload}
                                isInvalid={submitted && !!errors.rename}
                            />
                            <Form.Control.Feedback type="invalid">{errors.rename}</Form.Control.Feedback>
                        </Form.Group>


                    </Col>
                </Row>
                <MapModal
                    show={showMap}
                    handleClose={handleCloseMap}
                    latLng={latLng}
                    onSelectLocation={onSelectLocation}
                />
                {/* Next Button */}

                <div className='text-center my-2'>
                    <Button variant="warning mt-3" className="mt-4 w-75"
                        onClick={handleSubmit} disabled={isLoading}>
                        {isLoading && (
                            <div className="spinner-border spinner-border-sm text-white me-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                        Next
                    </Button>
                </div>
            </Form>


        </div>
    );
}

export default ShopDetails;


