import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form } from 'react-bootstrap';
import { ArrowLeft, Plus, Clock } from 'react-bootstrap-icons';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { FoodTimeAdd, FoodTimeUpdate, ListFoodTimes } from '../../utils/settingsApi';
import { useSelector } from 'react-redux';


export default function FoodTiming() {

    const shopID = useSelector(state => state.shop.shopID)

    const [title, setTitle] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [show, setShow] = useState(false);  // State to manage modal visibility
    const [foodSettingData, setFoodSettingData] = useState([]);
    const [isFoodTimeEdit, setIsFoodTimeEdit] = useState(false)
    const [currUpdateTimeID, setCurrUpdateTimeID] = useState(null);

    const onShow = () => setShow(true);       // Function to show modal
   ;     
    const onHide = () => { setShow(false); setStartTime(''); setEndTime(''); setIsFoodTimeEdit(false) };      // Function to hide modal

    const handleSubmit = (e) => {
        e.preventDefault();
    
        addFoodTiming();
        onHide(); // Close the modal after submission
    };


    const viewFoodSettings = useMutation(ListFoodTimes, {
        onSuccess: (res) => {
            console.log({ res });
            setFoodSettingData(res.Data)
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const addFoodTime = useMutation(FoodTimeAdd, {
        onSuccess: (res) => {
            // console.log({ res });
            fetchFoodTimeData();
        },
        onError: (error) => {
            console.error(error);
        },
    });
    const updateFoodTime = useMutation(FoodTimeUpdate, {
        onSuccess: (res) => {
            onHide()
            fetchFoodTimeData()
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const fetchFoodTimeData = async () => {

        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            await viewFoodSettings.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    };

    const addFoodTiming = async () => {
        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            formData.append('FromTime', startTime);
            formData.append('ToTime ', endTime);
            await addFoodTime.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    };

    const updateTiming = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            formData.append('FromTime', startTime);
            formData.append('ToTime', endTime);
            formData.append('PAvailTimeID', currUpdateTimeID);
            await updateFoodTime.mutateAsync(formData);

        } catch (err) {
            console.error(err);
        }

    }

    const editFoodTime = (timeID) => {
        setIsFoodTimeEdit(true)
        setCurrUpdateTimeID(timeID)
        const editval = foodSettingData.filter(val => val.PAvailTimeID == timeID);  // Use '===' for comparison

        if (editval.length > 0) {  // Check if any result is found
            setStartTime(editval[0].PAvailTimeFrom);  // Access the first item in the array
            setEndTime(editval[0].PAvailTimeTo);   // Access the first item in the array
            setShow(true);
        }
    }

    useEffect(() => {
        fetchFoodTimeData();
    }, [])


    return (
        <>
            {viewFoodSettings.isLoading ? <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '90vh',
            }}>
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div> :
                <Container className="mt-4">

                    <Link to={'/account/settings'} style={{ textDecoration: 'none', color: 'inherit' }} ><ArrowLeft size={24} /> </Link>
                    <Row className="my-4 align-items-center">
                        <Col>
                            <h1 className="mb-0">Food Timing</h1>
                        </Col>
                        <Col xs="auto">
                            <Button style={{ textDecoration: 'none' }} variant="link" className="text-warning p-0" onClick={onShow}>
                                <Plus size={24} />
                                <span className="ms-1">Add</span>
                            </Button>
                        </Col>
                    </Row>
                    {foodSettingData?.length > 0 ? (
                        foodSettingData.map((val) => {
                            const formatTime = (time) => {
                                const [hours, minutes] = time.split(":");
                                let period = 'AM';
                                let formattedHours = parseInt(hours, 10);

                                if (formattedHours >= 12) {
                                    period = 'PM';
                                    if (formattedHours > 12) {
                                        formattedHours -= 12;
                                    }
                                } else if (formattedHours === 0) {
                                    formattedHours = 12;
                                }

                                return `${formattedHours}:${minutes} ${period}`;
                            };

                            return (
                                <Card className='my-2' key={val.PAvailTimeID}>
                                    <Card.Body>
                                        <Row className="align-items-center">
                                            <Col>
                                                <h5 className="mb-0"></h5>
                                            </Col>
                                            <Col xs="auto" >
                                                <Button onClick={() => editFoodTime(val.PAvailTimeID)} variant="link" style={{ textDecoration: 'none' }} className="text-warning p-0">Edit</Button>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <span className="text-muted">{formatTime(val.PAvailTimeFrom)}</span>
                                            </Col>
                                            <Col xs="auto">
                                                <span className="text-muted">TO</span>
                                            </Col>
                                            <Col className="text-end">
                                                <span className="text-muted">{formatTime(val.PAvailTimeTo)}</span>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            );
                        })
                    )
                        :
                        <h5>Add New Timing</h5>}

                    <Modal show={show} onHide={onHide} centered>
                        <Modal.Body className="p-4">
                            <Form onSubmit={isFoodTimeEdit ? updateTiming : handleSubmit}>
                                {/* <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Breakfast"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </Form.Group> */}

                                <Form.Group className="mb-3">
                                    <Form.Label>Food Timing</Form.Label>
                                    <Row>
                                        <Col>

                                            <TimePicker
                                                onChange={(value) => setStartTime(value)}
                                                value={startTime}
                                                format="hh:mm a" // 12-hour format with AM/PM
                                                clearIcon={null} // You can hide the clear button if you prefer
                                                disableClock={true} // Optionally disable the clock icon
                                            />

                                            {/* <div className="position-relative">
                                        <Form.Control
                                            type="time"
                                            value={startTime}
                                            onChange={(e) => setStartTime(e.target.value)}
                                            required
                                        />
                                         <Clock className="position-absolute top-50 end-0 translate-middle-y me-2" /> 
                                    </div> */}
                                        </Col>
                                        <Col>

                                            <TimePicker
                                                onChange={(value) => setEndTime(value)}
                                                value={endTime}
                                                format="hh:mm a" // 12-hour format with AM/PM
                                                clearIcon={null} // You can hide the clear button if you prefer
                                                disableClock={true} // Optionally disable the clock icon
                                            />
                                          
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="outline-secondary" onClick={onHide}>
                                        Close
                                    </Button>
                                    <Button variant="warning" type="submit">
                                        {isFoodTimeEdit ? 'Update' : 'Add'}
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </Container>


            }
        </>
    );
}
