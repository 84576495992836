import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, InputGroup, Nav, Card, Button, Badge, Spinner } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { merchandChangeAvailableStatus, merchantDeleteProduct, merchantEnableProduct, merchantProductList } from '../../utils/api';
import { Link } from 'react-router-dom';
import { ArrowLeft, Search, Plus, ThreeDotsVertical, Pencil, Trash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export default function MenuList() {
  const queryClient = useQueryClient();
  const [activeCategory, setActiveCategory] = useState('All');
  const [catId, setCatID] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true); // Track if there are more pages to fetch
  const [searchTerm, setSearchTerm] = useState("");
  const [enablingProductId, setEnablingProductId] = useState(null);
  const [buttonLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const shopId = typeof window !== 'undefined' ? localStorage.getItem('7NMUser') || '' : '';

  // Fetch products for a specific page and category
  const { data, isLoading, error } = useQuery(
    ['merchantProducts', shopId, activeCategory, currentPage, searchTerm],
    () => merchantProductList(
      shopId,
      activeCategory === 'All' ? 0 : catId,
      currentPage,
      searchTerm
    ),
    {
      enabled: !!shopId,
      keepPreviousData: false,
    }
  );

  // Update products when new data is available
  useEffect(() => {
    if (data && data.products) {
      if (data.products.length > 0) {
        setProducts([...data.products]); // Replace products with the current page's products
        setHasMorePages(true); // There are more pages if products are returned
      } else {
        setHasMorePages(false); // No more pages if no products are returned
      }
      setIsFetching(false); // Stop spinner
    }
  }, [data]);

  const handleCategoryChange = async (categoryName, categoryId) => {
    setActiveCategory(categoryName);
    setCatID(categoryId);
    setCurrentPage(1); // Reset to first page on category change
    setIsFetching(true); // Start spinner
    await queryClient.invalidateQueries(['merchantProducts']); // Refetch for new category
  };

  const handlePageChange = (page) => {
    setCurrentPage(page); // Set the current page to the selected page
    setIsFetching(true); // Show spinner while fetching
  };

  const handleProductEnable = async (productId) => {
    try {
      setEnablingProductId(productId);
      setIsLoading(true);

      const result = await merchantEnableProduct(shopId, catId, productId);

      if (result.data.response_code === 1) {
        // Update the local products state immediately
        setProducts((prevProducts) =>
          prevProducts.map((item) =>
            item.ProMID === productId ? { ...item, waitingforApproval: 1, status: "Approved", IsEnable: 1 } : item
          )
        );

        queryClient.invalidateQueries(['merchantProducts', shopId, activeCategory]);
      }

    } catch (error) {
      console.error('Error enabling product:', error);
    } finally {
      setIsLoading(false);
      setEnablingProductId(null);
    }
  };

  const handleAvailabilityChange = async (productId, isAvailable) => {
    // Determine the new availability status
    const newStatus = isAvailable ? 1 : 0;

    try {
      const result = await merchandChangeAvailableStatus(shopId, catId, productId);

      if (result.data.response_code === 1) {
        // Update the local products state immediately
        setProducts((prevProducts) =>
          prevProducts.map((item) =>
            item.ProMID === productId ? { ...item, IsAvailable: newStatus } : item
          )
        );
      }
    } catch (error) {
      console.error('Error updating product availability:', error);
    }
  };



  const handleEdit = (productId) => {
    navigate(`/account/edit-product/${productId}`);
  };


  const handleDelete = (productId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this product? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Perform the deletion
          await merchantDeleteProduct(shopId, catId, productId);

          // Immediately update the UI
          setProducts((prevProducts) =>
            prevProducts.filter((item) => item.ProMID !== productId) // Remove the deleted product
          );

          queryClient.invalidateQueries(['merchantProducts', shopId, activeCategory]);

          Swal.fire('Deleted!', 'The product has been deleted.', 'success');

        } catch (error) {
          Swal.fire('Error', 'Failed to delete the product. Please try again.', 'error');
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'The product is safe!', 'info');
      }
    });
  };

  return (
    <Container className="p-3">
      <Row className="mb-3 align-items-center">
        <Col>
          <h2 className="mb-0">Menu List</h2>
        </Col>
        <Col xs="auto">
          <Link to={"/account/add-product"}>
            <Button variant="link" className="text-warning">
              <Plus size={24} /> Add
            </Button>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col className="d-flex justify-content-end">
          <InputGroup className="mb-3" style={{ width: "400px" }}>
            <InputGroup.Text>
              <Search />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Search Product"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setCurrentPage(1);
              }}
            />
          </InputGroup>
        </Col>
      </Row>

      <Nav variant="pills" className="mb-3 flex-nowrap overflow-auto">
        {data?.category.map((category) => (
          <Nav.Item key={category.categoryId}>
            <Nav.Link
              active={activeCategory === category.categoryName}
              onClick={() => handleCategoryChange(category.categoryName, category.categoryId)}
              className={`rounded-pill ${activeCategory === category.categoryName ? 'bg-warning text-white' : ''}`}
            >
              {category.categoryName}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>

      {isLoading || isFetching ? (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '90vh',
        }}>
          <div className="spinner-border text-warning" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          {products?.map((item) => (
            <Card key={item.ProMID} className="mb-2 container">
              <Card.Body className="d-flex justify-content-between align-items-center py-2">
                <Form.Check
                  type="checkbox"
                  id={`item-${item.ProMID}`}
                  label={item.ProMName}
                  checked={item.IsAvailable === 1}
                  disabled={item.status !== "Approved" || item.IsEnable === 0 || item.waitingforApproval == 1 || item.canChangeAttribute == 1} // Disable if not approved and not enabled
                  className={item.IsAvailable === 0 ? 'text-muted' : ''}
                  onChange={(e) => {
                    if (item.status === "Approved" && item.IsEnable === 1) {
                      handleAvailabilityChange(item.ProMID, e.target.checked);
                    }
                  }} />

                <div className="d-flex align-items-center">
                  {item.productRejected === 1 && (
                    <Badge bg="danger" className="me-2">Rejected</Badge>
                  )}

                  {item.status === "Approved" && (
                    <>
                      {item.waitingforApproval === 1 && (
                        <Badge bg="warning" className="me-2">Pending</Badge>
                      )}
                      {(item.waitingforApproval === 0 && item.IsAvailable === 1) && (
                        <>
                          <Button variant="link" className="p-0 me-2" onClick={() => handleEdit(item.ProMID)}>
                            <Pencil />
                          </Button>
                          <Button variant="link" className="p-0 text-danger" onClick={() => handleDelete(item.ProMID)}>
                            <Trash />
                          </Button>
                        </>
                      )}
                      {item.waitingforApproval === 1 && (
                        <>
                          <Button variant="link" className="p-0 me-2" onClick={() => handleEdit(item.ProMID)}>
                            <Pencil />
                          </Button>
                          <Button variant="link" className="p-0 text-danger" onClick={() => handleDelete(item.ProMID)}>
                            <Trash />
                          </Button>
                        </>
                      )}
                    </>
                  )}

                  {item.status === "Approved" && item.IsEnable === 0 && item.waitingforApproval != 1 && (
                    <>
                      {enablingProductId === item.ProMID ? (
                        <Button variant="link" className="p-0" disabled>
                          <Spinner animation="border" size="sm" className="me-1" /> Enabling...
                        </Button>
                      ) : (
                        <Button variant="link" className="p-0 text-info" onClick={() => handleProductEnable(item.ProMID)} disabled={isLoading}>
                          Enable
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Card.Body>
            </Card>
          ))}
          {/* Render Pagination */}
          <div className="d-flex justify-content-evenly mt-4">
            <Button
              variant="warning" // Add yellow color
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>

            <Button
              variant="warning" // Add yellow color
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={!hasMorePages}
            >
              Next
            </Button>
          </div>
        </>
      )}



    </Container>
  );
}
