import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet'; // Import L from Leaflet
import 'leaflet/dist/leaflet.css';
// import pin from '../../public/locationpin.jpg'
// Custom marker icon
const locationIcon = L.icon({
    iconUrl: '/locationpin.jpg', // Path to your custom marker image
    iconSize: [35, 45], // size of the icon
    iconAnchor: [17, 46], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -46], // point from which the popup should open relative to the iconAnchor
});

function LocationMarker({ onSelectLocation }) {
    const [position, setPosition] = useState(null);

    useMapEvents({
        click(e) {
            const { lat, lng } = e.latlng;
            onSelectLocation({ lat, lng });
            setPosition(e.latlng); // Set the marker position
        },
    });

    return position === null ? null : (
        <Marker position={position} icon={locationIcon}></Marker> // Use custom icon
    );
}

const MapModal = ({ show, handleClose, onSelectLocation, latLng }) => {
    if (!show) return null;

    return (
        <div className="map-modal">
            <div className="map-container">
                <MapContainer
                    center={[latLng.lat, latLng.lng]} // Default center point (you can change this)
                    zoom={13}
                    style={{ height: '400px', width: '100%' }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; '
                    />
                    <LocationMarker onSelectLocation={onSelectLocation} />
                    {latLng && (
                        <Marker position={[latLng.lat, latLng.lng]} icon={locationIcon}></Marker> // Use the custom icon
                    )}
                </MapContainer>
                <div className='text-end'>
                    <button className='btn btn-info mt-3' onClick={handleClose}>Confirm Location</button>
                </div>

            </div>
        </div>
    );
};

export default MapModal;
