import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Modal, Card } from 'react-bootstrap';
import { ArrowLeft, Clock, Pencil, Plus, TrashFill } from 'react-bootstrap-icons';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import { SettingShopAdd, SettingShopDelete, SettingShopUpdate, SettingShopView, SettingUpdate, ViewSetting } from '../../utils/settingsApi';
import { useSelector } from 'react-redux';


export default function Settings() {
    const shopID = useSelector(state => state.shop.shopID)

    const [openTime, setOpenTime] = useState('');
    const [closeTime, setCloseTime] = useState('');
    const [show, setShow] = useState(false);  // State to manage modal visibility
    const [settingData, setSettingData] = useState([]);
    const [isShopOpen, setIsShopOpen] = useState(null);
    const [isShopEdit, setIsShopEdit] = useState(false)
    const [currUpdateTimeID, setCurrUpdateTimeID] = useState(null);

    const onShow = () => setShow(true);       // Function to show modal
    const onHide = () => { setShow(false); setOpenTime(''); setCloseTime(''); setIsShopEdit(false) };      // Function to hide modal

    const convertTo24Hour = (time) => {
        let [hours, minutes, period] = time.match(/(\d{1,2}):(\d{2}) (\w{2})/);

        if (period.toUpperCase() === 'PM') {
            hours = parseInt(hours) + 12;
            if (hours === 24) {
                hours = 12;
            }
        } else if (period.toUpperCase() === 'AM' && hours === '12') {
            hours = '00';
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your onAdd logic here
        console.log({ openTime, closeTime });
        // const openTime24hr = convertTo24Hour(openTime);
        // const closeTime24hr = convertTo24Hour(closeTime);
        const openTime24hr = openTime;
        const closeTime24hr = closeTime;
        addShopTiming(openTime24hr, closeTime24hr)
        onHide(); // Close the modal after submission
    };
    const updateShop = async (e) => {

        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            formData.append('shopopentime', openTime);
            formData.append('shopclosetime', closeTime);
            formData.append('ShopTimingID', currUpdateTimeID);
            await updateShopTime.mutateAsync(formData);

        } catch (err) {
            console.error(err);
        }

    }

    const viewSettings = useMutation(SettingShopView, {
        onSuccess: (res) => {
            console.log({ res });
            setSettingData(res.data)
            setIsShopOpen(res.shopStatus)
        },
        onError: (error) => {
            console.error(error);
        },
    });
    const changeSettings = useMutation(SettingUpdate, {
        onSuccess: (res) => {
            console.log({ res });
            // setSettingData(res.Data)
            fetchSettingData()
        },
        onError: (error) => {
            console.error(error);
        },
    });
    const deleteSetting = useMutation(SettingShopDelete, {
        onSuccess: (res) => {
            console.log({ res });
            // setSettingData(res.Data)
            fetchSettingData()
        },
        onError: (error) => {
            console.error(error);
        },
    });

    const addShopTime = useMutation(SettingShopAdd, {
        onSuccess: (res) => {
            console.log({ res });
            // setSettingData(res.Data)
            fetchSettingData()
        },
        onError: (error) => {
            console.error(error);
        },
    });
    const updateShopTime = useMutation(SettingShopUpdate, {
        onSuccess: (res) => {
            console.log({ res });
            // setSettingData(res.Data)
            fetchSettingData()
        },
        onError: (error) => {
            console.error(error);
        },
    });
    const fetchSettingData = async () => {

        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            await viewSettings.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    };

    const addShopTiming = async (openTime24hr, closeTime24hr) => {
        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            formData.append('shopopentime', openTime24hr);
            formData.append('shopclosetime', closeTime24hr);
            await addShopTime.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    };

    const changeSetingStatus = async (status) => {
        try {
            const formData = new FormData();
            formData.append('ShopID', shopID); // Use the Redux store value
            formData.append('settingstatus', status);
            await changeSettings.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    };

    const deleteSetttings = async (timeID) => {
        try {
            const formData = new FormData();
            formData.append('ShopTimingID', timeID); // Use the Redux store value
            // formData.append('settingstatus', status);
            await deleteSetting.mutateAsync(formData);
        } catch (err) {
            console.error(err);
        }
    }

    const editShopTime = (timeID) => {
        setIsShopEdit(true)
        setCurrUpdateTimeID(timeID)
        const editval = settingData.filter(val => val.ShopTimingID == timeID);  // Use '===' for comparison

        if (editval.length > 0) {  // Check if any result is found
            setOpenTime(editval[0].ShopTimingStart);  // Access the first item in the array
            setCloseTime(editval[0].ShopTimingEnd);   // Access the first item in the array
            setShow(true);
        }
    }

    useEffect(() => {
        fetchSettingData();
    }, [])


    return (
        <>
            {viewSettings.isLoading ? <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '90vh',
            }}>
                <div className="spinner-border text-warning" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div> :
                <Container className="mt-4">
                    <Row className="mb-4 align-items-center">
                        <Col>
                            <h1 className="mb-0">Settings</h1>
                        </Col>
                        <Col xs="auto">
                            <Button variant="link" style={{ textDecoration: 'none' }} className="text-warning p-0" onClick={onShow}>
                                <Plus size={24} />
                                <span className="ms-1">Add</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <div className="d-flex justify-content-between align-items-center p-3 bg-light rounded">
                                <span>Accept all Order</span>
                                <Form.Check
                                    type="switch"
                                    id="accept-all-order"
                                    checked={isShopOpen == 1}
                                    onChange={(e) => changeSetingStatus(e.target.checked ? 1 : 0)}
                                    style={{
                                        transform: 'scale(1.5)', // Increases overall size
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Link to={"/account/food-timing"} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="d-flex justify-content-between align-items-center p-3 bg-light rounded">
                                    <span>Food Timing</span>
                                    <Clock size={24} />
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    {settingData?.length > 0 ? (
                        settingData.map((val) => {
                            const formatTime = (time) => {
                                const [hours, minutes] = time.split(":");
                                let period = 'AM';
                                let formattedHours = parseInt(hours, 10);

                                if (formattedHours >= 12) {
                                    period = 'PM';
                                    if (formattedHours > 12) {
                                        formattedHours -= 12;
                                    }
                                } else if (formattedHours === 0) {
                                    formattedHours = 12;
                                }

                                return `${formattedHours}:${minutes} ${period}`;
                            };

                            return (
                                <Card className='my-2' key={val.ShopTimingID}>
                                    <Card.Body>
                                        <Row className="align-items-center">
                                            <Col>
                                                <h5 className="mb-0"></h5>
                                            </Col>
                                            <Col xs="auto" >
                                                {val.ShopTimingStatus == 0 ? <span className="text-warning p-0 me-2">Pending</span> : (<>
                                                    {/* <Button onClick={() => editShopTime(val.ShopTimingID)} variant="link" style={{ textDecoration: 'none' }} className="text-secondary p-0 me-2">Edit</Button> */}
                                                    <Pencil onClick={() => editShopTime(val.ShopTimingID)} variant="link" style={{ textDecoration: 'none' }} className="text-secondary p-0 me-2">Edit</Pencil>
                                                    <TrashFill
                                                        onClick={() => {
                                                            const confirmDelete = window.confirm(`Are you sure you want to delete this timing ${formatTime(val.ShopTimingStart)} to ${formatTime(val.ShopTimingEnd)}?`);
                                                            if (confirmDelete) {
                                                                deleteSetttings(val.ShopTimingID);
                                                            }
                                                        }}
                                                        className="text-danger"
                                                    /></>)}

                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <span className="text-muted">{formatTime(val.ShopTimingStart)}</span>
                                            </Col>
                                            <Col xs="auto">
                                                <span className="text-muted">TO</span>
                                            </Col>
                                            <Col className="text-end">
                                                <span className="text-muted">{formatTime(val.ShopTimingEnd)}</span>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            );
                        })
                    )
                        :
                        <h5>Add Shop Time</h5>
                    }

                    <Modal show={show} onHide={onHide} centered>
                        <Modal.Body className="p-4">
                            <Form onSubmit={isShopEdit ? updateShop : handleSubmit}>


                                <Form.Group className="mb-3">
                                    <Form.Label>Shop Timing</Form.Label>
                                    <Row>
                                        <Col>

                                            <TimePicker
                                                onChange={(value) => setOpenTime(value)}
                                                value={openTime}
                                                format="hh:mm a" // 12-hour format with AM/PM
                                                clearIcon={null} // You can hide the clear button if you prefer
                                                disableClock={true} // Optionally disable the clock icon
                                            />

                                        </Col>
                                        <Col>

                                            <TimePicker
                                                onChange={(value) => setCloseTime(value)}
                                                value={closeTime}
                                                format="hh:mm a" // 12-hour format with AM/PM
                                                clearIcon={null} // You can hide the clear button if you prefer
                                                disableClock={true} // Optionally disable the clock icon
                                            />

                                        </Col>
                                    </Row>
                                </Form.Group>

                                <div className="d-flex justify-content-between mt-4">
                                    <Button variant="outline-secondary" onClick={onHide}>
                                        Close
                                    </Button>

                                    <Button variant="warning" type="submit">
                                        {isShopEdit ? 'Update' : 'Add'}
                                    </Button>
                                </div>
                            </Form>
                        </Modal.Body>
                    </Modal>
                </Container>
            }
        </>

    );
}