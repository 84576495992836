import React, { useRef, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, Modal, Image } from 'react-bootstrap';
import { ArrowLeft, Image as ImageIcon, Trash, X, Plus } from 'react-bootstrap-icons';
import { addProduct, getShopTiming, merchantAddCategory, merchantGetCategories, merchantGetSubCategories } from '../../utils/api';
import * as z from 'zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const categorySchema = z.object({
  categoryName: z.string().min(1, 'Category name is required'),
  subCategories: z.array(z.string().min(1, 'Subcategory name is required')),
  image: z.instanceof(File, "Image is required"),
});

const productSchema = z.object({
  shopid: z.union([z.string(), z.number()]),
  name: z.string().min(1, "Product name is required"),
  description: z.string().min(5, "Product description must be at least 5 characters long"),
  category: z.string().min(1, "Category is required"),
  subcategory: z.array(z.number()).nonempty("At least one subcategory must be selected"),
  productOrderType: z.union([z.string(), z.number()]),
  PAvailTimeIDs: z.array(z.union([z.string(), z.number()])).nonempty("At least one food timing must be selected"),
  // ProSIsCustomized: z.union([z.string(), z.number()]),
  // addons: z.array(
  //   z.object({
  //     AddonShopID: z.union([z.string(), z.number()]),
  //     AddonTitle: z.string().min(1, "Addon title is required"),
  //     AddonName: z.string().min(1, "Addon name is required"),
  //     AddonPrice: z.union([z.string(), z.number()]),
  //   })
  // ).nonempty("At least one addon must be added"),
  ProSPackingAmt: z.union([z.string(), z.number()]).optional(),
  price: z.string().min(1, "Base Price is required"),
  salePrice: z.union([
    z.string().refine(value => !isNaN(Number(value)) && Number(value) >= 1, {
      message: "Selling Price is required and must be greater than or equal to 1",
    }),
    z.number().min(1, "Selling Price is required and must be greater than or equal to 1"),
  ]),
  GstID: z.union([z.string(), z.number()]),
  type: z.union([z.string(), z.number()]),
  image: z.instanceof(File, "Product image is required"),
});

export default function AddProduct() {
  const [customizable, setCustomizable] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [selectedFoodTimings, setSelectedFoodTimings] = useState([]);
  const [selectedGstId, setSelectedGstId] = useState('');
  const [selectedType, setSelectedType] = useState("1");
  const [productImage, setProductImage] = useState(null);
  const [productImagePreview, setProductImagePreview] = useState(null);
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [packageCharge, setPackageCharge] = useState('');
  const [validationErrors, setErrors] = useState({});

  const [basePrice, setBasePrice] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [commissionValue, setCommissionValue] = useState(0);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const orderTypes = [
    { label: 'Instant order', id: 1 },
    { label: 'Pre order', id: 2 },
    { label: 'Both', id: 3 },
  ];

  const handleProductImageChange = (e) => {
    const file = e.target.files[0]; // Get the first file
    if (file) {
      setProductImage(file); // Store the selected file
      const reader = new FileReader();
      reader.onloadend = () => {
        setProductImagePreview(reader.result); // Set image preview URL
      };
      reader.readAsDataURL(file); // Read file as data URL to display preview
    }
  };

  const handleCheckboxChange = (id) => {
    // Set the selected order type to the clicked ID
    setSelectedOrderType(selectedOrderType === id ? null : id); // Deselect if already selected
  };

  const handleProductNameChange = (e) => setProductName(e.target.value);
  const handleProductDescriptionChange = (e) => setProductDescription(e.target.value);

  const handleSubCategoryCheckboxChange = (subcategoryId) => {
    setSelectedSubCategories((prevSelected) => {
      if (prevSelected.includes(subcategoryId)) {
        // If already selected, remove it from the array (deselect)
        return prevSelected.filter((id) => id !== subcategoryId);
      } else {
        // If not selected, add it to the array (select)
        return [...prevSelected, subcategoryId];
      }
    });
  };

  const handleFoodTimingChange = (timingId) => {
    setSelectedFoodTimings((prevSelected) => {
      if (prevSelected.includes(timingId)) {
        // If already selected, remove it from the array (deselect)
        return prevSelected.filter((id) => id !== timingId);
      } else {
        // If not selected, add it to the array (select)
        return [...prevSelected, timingId];
      }
    });
  };

  const ProSIsCustomized = customizable ? 0 : 1;

  const handleSwitchChange = (e) => {
    // Update the customizable state
    setCustomizable(e.target.checked);
    // Here you can save ProSIsCustomized to your desired location (like an API or state)
    console.log('ProSIsCustomized:', ProSIsCustomized); // For demonstration
  };

  const handleGstChange = (e) => {
    const gstId = e.target.value;
    setSelectedGstId(gstId);
  };

  const handleTypeChange = (e) => {
    const selectedOption = e.target.value;
    // Set 1 for Non-Veg, 0 for Veg
    const typeValue = selectedOption === 'Non - Veg' ? 1 : 2;
    setSelectedType(typeValue);
  };


  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryName: '',
      subCategories: [''],
      image: null,
    },
    resolver: zodResolver(categorySchema),
  });

  const shopId = typeof window !== 'undefined' ? localStorage.getItem('7NMUser') || '' : '';

  const mutation = useMutation(merchantAddCategory, {
    onSuccess: (data) => {
      if (data.status === false) {
        setShowCategoryModal(true);

      } else {
        queryClient.refetchQueries(['getCategories', shopId]);

        setShowCategoryModal(false);

      }
    },
    onError: (error) => {
      console.error(error);
    },
  });


  const onSubmit = async (formData) => {
    try {
      const formDataToSubmit = new FormData();
      const subcategoryString = Array.isArray(formData.subCategories) ? formData.subCategories.join(',') : '';

      formDataToSubmit.append('shopId', shopId);
      formDataToSubmit.append('category', formData.categoryName);
      // formDataToSubmit.append('subcategory', JSON.stringify(formData.subCategories));
      formDataToSubmit.append('subcategory', subcategoryString);
      if (selectedImage) {
        formDataToSubmit.append('image', selectedImage);
      }

      const response = await mutation.mutateAsync(formDataToSubmit);

      if (response.status === false) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.message || 'An error occurred. Please try again.',
          confirmButtonText: 'OK',
        });
        return;
      }

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Category added successfully!',
        confirmButtonText: 'OK',
      });
    } catch (err) {
      console.error(err);
    }
  };


  const handleAddSubCategory = () => {
    const subCategories = watch('subCategories');
    setValue('subCategories', [...subCategories, '']);
  };

  const handleRemoveSubCategory = (index) => {
    const subCategories = watch('subCategories');
    setValue('subCategories', subCategories.filter((_, i) => i !== index));
  };


  const handleImageChange = (event) => {
    console.log("op", event)
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      setValue('image', file);
    } else {
      setImagePreview(null);

      setSelectedImage(null);
      setValue('image', null);
    }
  };

  const [sections, setSections] = useState([
    {
      title: '',
      variants: [{ name: '', price: '' }],
    },
  ]);

  const handleAddSection = () => {
    setSections([...sections, { title: '', variants: [{ name: '', price: '' }] }]);

  };

  const handleRemoveSection = (sectionIndex) => {
    const updatedSections = sections.filter((_, i) => i !== sectionIndex);
    setSections(updatedSections);
  };

  const handleSectionTitleChange = (sectionIndex, value) => {
    const updatedSections = sections.map((section, i) =>
      i === sectionIndex ? { ...section, title: value } : section
    );
    setSections(updatedSections);
  };

  const handleAddVariant = (sectionIndex) => {
    const updatedSections = sections.map((section, i) =>
      i === sectionIndex
        ? { ...section, variants: [...section.variants, { name: '', price: '' }] }
        : section
    );
    setSections(updatedSections);
  };

  const handleRemoveVariant = (sectionIndex, variantIndex) => {
    const updatedSections = sections.map((section, i) =>
      i === sectionIndex
        ? {
          ...section,
          variants: section.variants.filter((_, vi) => vi !== variantIndex),
        }
        : section
    );
    setSections(updatedSections);
  };


  const handleVariantChange = (sectionIndex, variantIndex, field, value) => {

    console.log("o-p", field)
    const numericValue = Number(value);

    // Check if the field should not allow negative values
    if (field === 'price' && numericValue < 0) {
      return; // Ignore the update if the value is negative
    }

    const updatedSections = sections.map((section, i) =>
      i === sectionIndex
        ? {
          ...section,
          variants: section.variants.map((variant, vi) =>
            vi === variantIndex ? { ...variant, [field]: value } : variant
          ),
        }
        : section
    );
    setSections(updatedSections);
  };

  const { data: categories, isLoading, error } = useQuery(
    ['getCategories', shopId],
    () => merchantGetCategories(shopId),
    {
      staleTime: 5 * 60 * 1000,  // Cache for 5 minutes (adjust as needed)
      cacheTime: 10 * 60 * 1000,  // Keep cache for 10 minutes (adjust as needed)
    }
  );

  const { data: subcategories, isLoading: subLoading, error: subError } = useQuery(
    ['getSubCategories', selectedCategory],
    () => merchantGetSubCategories(selectedCategory),
    {
      enabled: !!selectedCategory,
    }
  );

  const handleCategoryChange = (e) => {
    const catID = e.target.value;
    setSelectedCategory(catID);
  };

  const { data: getShopTime, isLoading: shopTimeLoading, error: timeError } = useQuery(
    ['getTiming', shopId],
    () => getShopTiming(shopId),
    {
      staleTime: 5 * 60 * 1000,  // Cache for 5 minutes (adjust as needed)
      cacheTime: 10 * 60 * 1000,  // Keep cache for 10 minutes (adjust as needed)
    }
  );

  // console.log("time", getShopTime.commission)

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(':');
    const hour12 = hour % 12 || 12; // Convert to 12-hour format
    const period = hour < 12 ? 'AM' : 'PM'; // Determine AM or PM
    return `${hour12}:${minute} ${period}`;
  };

  //For pricing calculation
  useEffect(() => {
    if (basePrice) {
      const matchingCommission = getShopTime?.comission.find(
        (commission) =>
          basePrice >= commission.CommissionPriceFrom &&
          basePrice <= commission.CommissionPriceTo
      );

      if (matchingCommission) {
        const newSellingPrice = parseFloat(basePrice) + matchingCommission.CommissionValue;
        setSellingPrice(Number(newSellingPrice)); // Update selling price without extra zeros
        setCommissionValue(matchingCommission.CommissionValue);
      }
    }
  }, [basePrice, getShopTime]);

  const handleBasePriceInput = (e) => {
    const value = e.target.value;

    const numericValue = parseFloat(value);
    if (!isNaN(numericValue) && numericValue >= 0) {
      setBasePrice(value); // Update state if valid
    } else if (value === '') {
      setBasePrice(''); // Allow clearing the input
    } else {
      e.target.value = basePrice; // Reset input to the last valid value
    }
  };


  const handleSellingPriceChange = (e) => {
    const newSellingPrice = parseFloat(e.target.value);

    if (isNaN(newSellingPrice) || newSellingPrice < 0) {
      setSellingPrice('');
      setBasePrice('');
      return;
    }

    setSellingPrice(newSellingPrice);

    const matchingMaxRange = getShopTime.comissionMaxRange.find(
      (range) =>
        newSellingPrice >= range.CommissionSalePriceMin &&
        newSellingPrice <= range.CommissionSalePriceMax
    );

    if (matchingMaxRange) {
      const newBasePrice = newSellingPrice - matchingMaxRange.CommissionValue;

      if (newBasePrice >= 0) {
        setBasePrice(Number(newBasePrice)); // Update base price without extra zeros
        setCommissionValue(matchingMaxRange.CommissionValue); // Set the commission value
      } else {
        setBasePrice(0); // Prevent negative base price
      }
    } else {
      // Reset the base price and commission if the selling price is outside the valid range
      setBasePrice('');
      setCommissionValue(0);
    }
  };

  useEffect(() => {
    if (basePrice) {
      const numericBasePrice = parseFloat(basePrice);

      if (numericBasePrice === 0) {
        // If base price is 0, set selling price to 0
        setSellingPrice(0);
        setCommissionValue(0); // Reset commission value
        return; // Exit early
      }

      const matchingCommission = getShopTime?.comission.find(
        (commission) =>
          numericBasePrice >= commission.CommissionPriceFrom &&
          numericBasePrice <= commission.CommissionPriceTo
      );

      if (matchingCommission) {
        const newSellingPrice = numericBasePrice + matchingCommission.CommissionValue;
        setSellingPrice(Number(newSellingPrice)); // Update selling price without extra zeros
        setCommissionValue(matchingCommission.CommissionValue);
      } else {
        // If no matching commission is found, set selling price to 0
        setSellingPrice(0);
        setCommissionValue(0); // Reset commission value
      }
    } else {
      // If basePrice is empty or invalid, set sellingPrice to 0
      setSellingPrice('');
      setCommissionValue(0);
    }
  }, [basePrice, getShopTime]);


  const handleProductSubmit = (e) => {
    e.preventDefault();

    const formData = {
      shopid: shopId,
      name: productName,
      description: productDescription,
      category: selectedCategory,
      subcategory: selectedSubCategories,
      productOrderType: selectedOrderType,
      PAvailTimeIDs: selectedFoodTimings,
      ProSIsCustomized: customizable ? 1 : 0,
      addons: sections.flatMap((section) =>
        section.variants.map((variant) => ({
          AddonShopID: parseInt(shopId),
          AddonTitle: section.title || '',
          AddonName: variant.name || '',
          AddonPrice: parseFloat(variant.price) || 0,
        }))
      ),
      ProSPackingAmt: packageCharge || 0,
      price: basePrice,
      salePrice: sellingPrice,
      units: [4],
      Unitval: [6],

      GstID: selectedGstId,
      type: selectedType,
      image: productImage, // This is assumed to be managed from the file input
    };

    const validation = productSchema.safeParse(formData);

    console.log("Validation object:", validation);

    if (!validation.success) {
      console.log("Validation failed");
      const newErrors = {};
      validation.error.issues.forEach(issue => {
        newErrors[issue.path[0]] = issue.message;
      });
      setErrors(newErrors);
      return;
    }

    setErrors({});

    productUpload.mutate(formData);
  };

  // Mutation hook for product upload
  const productUpload = useMutation(addProduct, {
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: 'Product Added',
        text: 'Your product has been successfully added!',
        confirmButtonText: 'OK',
      }).then((result) => {
        if (result.isConfirmed) {
          // Navigate to a specific route or back
          navigate(-1);
        }
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'Failed to add the product. Please try again.',
        confirmButtonText: 'OK',
      });
    },
  });


  return (
    <Container className="py-4">
      <Row className="mb-4 align-items-center">
        <Col>
          <h2 className="mb-0">
            Add Product
          </h2>
        </Col>
      </Row>

      <Form onSubmit={handleProductSubmit}>
        <Card className="mb-4 text-center">
          <Card.Body>
            {productImagePreview ? (
              <Image src={productImagePreview} alt="Product Preview" thumbnail fluid className="mb-2" width={150}
                height={150} />
            ) : (
              <ImageIcon size={48} className="text-warning mb-2" />
            )}
            <p className="text-muted">Product Image</p>
            <p className="text-muted small">JPG, PNG</p>

            <Form.Control
              type="file"
              isInvalid={!!validationErrors.image}
              className="d-none"
              id="productImage"
              accept="image/*"
              onChange={handleProductImageChange}
            />
            <Button variant="outline-secondary" onClick={() => document.getElementById('productImage').click()}>
              Choose File
            </Button>
            <Form.Control.Feedback type="invalid">
              {validationErrors.image}
            </Form.Control.Feedback>
          </Card.Body>
        </Card>

        <Form.Group className="mb-3">
          <Form.Label>Product Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Product Name"
            isInvalid={!!validationErrors.name}
            value={productName}
            onChange={handleProductNameChange}
          />
          <Form.Control.Feedback type="invalid">
            {validationErrors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Product Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            isInvalid={!!validationErrors.description}
            placeholder="Enter Description"
            value={productDescription}
            onChange={handleProductDescriptionChange}
          />
          <Form.Control.Feedback type="invalid">
            {validationErrors.description}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Category</Form.Label>
          <div className="d-flex">
            <Form.Select className="me-2" onChange={handleCategoryChange} isInvalid={!!validationErrors.category}>
              {/* Map categories to select options */}
              {categories && categories?.Data?.length > 0 ? (
                <>
                  <option value="">Select category</option>
                  {categories.Data.map((category) => (
                    <option key={category.CatID} value={category.CatID}>
                      {category.CatName}
                    </option>
                  ))}
                </>
              ) : (
                <option>No categories available</option>
              )}
            </Form.Select>
            {/* Button to open modal for adding new categories */}
            <Button variant="outline-secondary" onClick={() => setShowCategoryModal(true)}>
              <Plus />
            </Button>
          </div>
          <Form.Control.Feedback type="invalid">
            {validationErrors.category}
          </Form.Control.Feedback>
          {/* Conditionally show the modal */}
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Sub Category</Form.Label>
          <div>
            {subLoading ? (
              <div>Loading subcategories...</div>
            ) : subError ? (
              <div>{subError.message}</div>
            ) : subcategories && subcategories[0]?.Data.length > 0 ? (
              subcategories[0].Data.map((subcategory) => (
                <Form.Check
                  inline
                  isInvalid={!!validationErrors.subcategory}
                  type="checkbox"
                  id={`subcategory-${subcategory.CatID}`}
                  label={subcategory.CatName}
                  key={subcategory.CatID}
                  checked={selectedSubCategories.includes(subcategory.CatID)}
                  onChange={() => handleSubCategoryCheckboxChange(subcategory.CatID)}
                />
              ))
            ) : (
              <div>No subcategories available</div>
            )}
          </div>
          <Form.Control.Feedback type="invalid">
            {validationErrors.subcategory}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Choose order type</Form.Label>
          <div>
            {orderTypes.map((orderType) => (
              <Form.Check
                inline
                type="checkbox"
                isInvalid={!!validationErrors.productOrderType}
                id={`order-type-${orderType.id}`}
                label={orderType.label}
                key={orderType.id}
                checked={selectedOrderType === orderType.id}
                onChange={() => handleCheckboxChange(orderType.id)}
              />
            ))}
          </div>
          <Form.Control.Feedback type="invalid">
            {validationErrors.productOrderType}
          </Form.Control.Feedback>
        </Form.Group>

        {shopTimeLoading ? (
          <div>Loading food timings...</div>
        ) : timeError ? (
          <div>Error loading food timings: {timeError.message}</div>
        ) : getShopTime && getShopTime?.Data?.length > 0 ? (
          <Form.Group className="mb-3">
            <Form.Label>Food Timing</Form.Label>
            {getShopTime?.Data?.map((item, index) => (
              <div
                key={index}
                className="p-2 mb-2"
                style={{ backgroundColor: '#f8f9fa', borderRadius: '5px' }}
              >
                <Form.Check
                  isInvalid={!!validationErrors.PAvailTimeIDs}
                  type="checkbox"
                  id={`timing-${index}`}
                  label={`From: ${convertTo12HourFormat(item.PAvailTimeFrom)} To: ${convertTo12HourFormat(item.PAvailTimeTo)}`}
                  checked={selectedFoodTimings.includes(item.PAvailTimeID)} // Check if this ID is selected
                  onChange={() => handleFoodTimingChange(item.PAvailTimeID)} // Update selection on change
                />
              </div>
            ))}
            <Form.Control.Feedback type="invalid">
              {validationErrors.PAvailTimeIDs}
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          <div>No food timings available</div>
        )}

        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            id="customizable"
            label="Customisable"
            checked={customizable}
            onChange={handleSwitchChange}
          />
        </Form.Group>

        {customizable && (
          <>
            {sections.map((section, sectionIndex) => (
              <div key={sectionIndex} className="mb-4">
                <Row className="align-items-center mb-2">
                  <Col>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Size"
                        value={section.title}
                        onChange={(e) => handleSectionTitleChange(sectionIndex, e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-danger"
                      onClick={() => handleRemoveSection(sectionIndex)}
                    >
                      <Trash />
                    </Button>
                  </Col>
                </Row>

                {section.variants.map((variant, variantIndex) => (
                  <Card key={variantIndex} className="mb-3">
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Enter Addon"
                            value={variant.name}
                            onChange={(e) =>
                              handleVariantChange(sectionIndex, variantIndex, 'name', e.target.value)
                            }
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            type="number"
                            placeholder="Enter Price"
                            value={variant.price}
                            onChange={(e) =>
                              handleVariantChange(sectionIndex, variantIndex, 'price', e.target.value)
                            }
                          />
                        </Col>
                        <Col xs="auto">
                          <Button
                            variant="link"
                            className="text-danger"
                            onClick={() => handleRemoveVariant(sectionIndex, variantIndex)}
                          >
                            <Trash />
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}

                <Button
                  variant="outline-warning"
                  className="w-100"
                  onClick={() => handleAddVariant(sectionIndex)}
                >
                  Add Variant
                </Button>
              </div>
            ))}

            <Button variant="outline-success" className="w-100 mb-4" onClick={handleAddSection}>
              Add New Section
            </Button>
          </>
        )}

        <Row className="mb-3">
          <Col xs={12} sm={6} md={6}>
            <Form.Group>
              <Form.Label>Base Price</Form.Label>
              <Form.Control
                isInvalid={!!validationErrors.price}
                type="text"
                placeholder="Enter Base Price"
                value={basePrice}
                onChange={handleBasePriceInput}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.price}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} sm={6} md={6}>
            <Form.Group>
              <Form.Label>Selling Price</Form.Label>
              <Form.Control
                isInvalid={!!validationErrors.salePrice}
                type="text"
                placeholder="Selling Price"
                value={sellingPrice}
                disabled
                onChange={handleSellingPriceChange}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.salePrice}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={12} sm={6} md={4}>
            <Form.Group>
              <Form.Label>Package Charge</Form.Label>
              <Form.Control
                isInvalid={!!validationErrors.ProSPackingAmt}
                type="text"
                placeholder="Enter Package Price"
                value={packageCharge}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9]/g, '');
                  setPackageCharge(value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.ProSPackingAmt}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group>
              <Form.Label>Product GST (%)</Form.Label>
              <Form.Select aria-label="Select Product GST" onChange={handleGstChange} isInvalid={!!validationErrors.GstID}
              >
                <option value="">
                  Select GST
                </option>
                {categories?.Gst.length > 0 ? (
                  categories?.Gst?.map((gstValue, index) => (
                    <option value={gstValue.GstID} key={index}>
                      {gstValue.GstValue}%
                    </option>
                  ))
                ) : (
                  <option>No GST available for this category</option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {validationErrors.GstID}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Select onChange={handleTypeChange} isInvalid={!!validationErrors.type}>
                <option value="Non - Veg">Non - Veg</option>
                <option value="Veg">Veg</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {validationErrors.type}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* <Button variant="outline-secondary" className="mb-4 w-100">Add more section</Button> */}

        <Button variant="warning" type="submit" className="w-100 mt-4" size="lg" disabled={productUpload.isLoading} >
          {productUpload.isLoading ? 'Adding...' : 'Add Product'}
        </Button>
      </Form>


      <Modal show={showCategoryModal} onHide={() => setShowCategoryModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label htmlFor="categoryName" className="form-label">Category Name</label>
              <input
                type="text"
                className={`form-control ${errors.categoryName ? 'is-invalid' : ''}`} // Add 'is-invalid' class if there's an error
                id="categoryName"
                placeholder="Fast Food"
                {...register('categoryName')}
              />
              {errors.categoryName && ( // Display error message
                <div className="invalid-feedback">
                  {errors.categoryName.message}
                </div>
              )}
            </div>

            {/* Sub Categories Handling */}
            <div className="mb-3">
              <label className="form-label">Sub Categories</label>
              {watch('subCategories').map((subCategory, index) => (
                <>
                  <div key={index} className="d-flex mb-2">
                    <div style={{ width: "100%" }}>
                      <input
                        type="text"
                        className={`form-control ${errors.subCategories?.[index] ? 'is-invalid' : ''}`} // Check for errors in subCategories
                        placeholder="Sub Category Name"
                        {...register(`subCategories.${index}`)}
                      />
                      {errors.subCategories?.[index] && (
                        <div className="invalid-feedback">
                          {errors.subCategories[index].message}
                        </div>
                      )}
                    </div>

                    <Button variant="link" className="text-danger" onClick={() => handleRemoveSubCategory(index)}>
                      Remove
                    </Button>
                  </div>

                </>
              ))}

              <Button variant="outline-warning" onClick={handleAddSubCategory}>
                Add Sub Category
              </Button>

            </div>


            {/* Image Upload Section */}
            <div className="mb-4 text-center">
              <ImageIcon size={48} className="text-warning mb-2" />
              <p className="text-muted">Category Image</p>
              <p className="text-muted small">JPG, PNG</p>
              <input
                type="file"
                id="image-upload"
                {...register('image')}
                ref={fileInputRef}
                onChange={handleImageChange}
                accept="image/*"
                className="d-none"
              />
              <Button
                variant="outline-secondary"
                onClick={() => fileInputRef.current?.click()}
              >
                Choose File
              </Button>
              {imagePreview && ( // Conditionally render the image preview
                <div className="mt-3">
                  <Image
                    src={imagePreview}
                    alt="Preview"
                    width={150}
                    height={150}
                    className="rounded"
                  />
                </div>
              )}

              {errors.image && <div className="text-danger">{errors.image.message}</div>}

            </div>

            <Button variant="warning" type="submit" disabled={mutation.isLoading}>
              {mutation.isLoading ? 'Creating...' : 'Create Category'}
            </Button>
          </form>
        </Modal.Body>
      </Modal>


    </Container>
  );
}