import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    
}

export const ValidateSlice = createSlice({
    name: 'Validation',
    initialState,
    reducers: {

    }
})


export const numberValidate = (phoneNumber) => (dispatch) => {
    let isValid = true;
    let errorMessage = '';

    // Check if the phone number is exactly 10 digits
    if (!/^\d{10}$/.test(phoneNumber)) {
        isValid = false;
        errorMessage = 'Phone number must be exactly 10 digits.';
    }

    
    return isValid;
};
export const { } = ValidateSlice.actions

export default ValidateSlice.reducer