import React, { useState } from 'react';
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Bell, House, Calendar2, Person, List } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';

export const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar bg="white" expand="lg" className="shadow-sm">
        <Container fluid>
          <Navbar.Brand as={Link} to="/home" className="fw-bold text-warning">
            7NIAN
          </Navbar.Brand>
          <div className="d-flex align-items-center">
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-lg-none border-0" onClick={handleShow}>
              <List size={24} />
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end d-none d-lg-flex">
            <Nav>
              <Nav.Link 
                as={Link} 
                to="/home" 
                className={`text-center py-2 ${location.pathname === '/home' ? 'text-warning active' : ''}`}
              >
                <House size={24} className="me-2" />
                Home
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/orders" 
                className={`text-center py-2 ${location.pathname === '/orders' ? 'text-warning active' : ''}`}
              >
                <Calendar2 size={24} className="me-2" />
                Orders
              </Nav.Link>
              <Nav.Link 
                as={Link} 
                to="/account" 
                className={`text-center py-2 ${location.pathname === '/account' ? 'text-warning active' : ''}`}
              >
                <Person size={24} className="me-2" />
                Account
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold text-warning">7NIAN</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link 
              as={Link} 
              to="/home" 
              className={`py-2 ${location.pathname === '/home' ? 'text-warning active' : ''}`} 
              onClick={handleClose}
            >
              <House size={24} className="me-2" />
              Home
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/orders" 
              className={`py-2 ${location.pathname === '/orders' ? 'text-warning active' : ''}`} 
              onClick={handleClose}
            >
              <Calendar2 size={24} className="me-2" />
              Orders
            </Nav.Link>
            <Nav.Link 
              as={Link} 
              to="/account" 
              className={`py-2 ${location.pathname === '/account' ? 'text-warning active' : ''}`} 
              onClick={handleClose}
            >
              <Person size={24} className="me-2" />
              Account
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
